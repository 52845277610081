import React from 'react';
import '../static/css/App.css'; // CSS file for styling

const LocationUnserviceable = () => {
  return (
    <div className="unserviceable-container">
      {/* Image/Illustration */}
      <div className="unserviceable-image-container">
        {/* <img
          src="/path/to/your/image.png"  // Replace with actual image path
          alt="Location Unserviceable"
          className="unserviceable-image"
        /> */}
      </div>

      {/* Title */}
      <h2 className="unserviceable-title">Location Unserviceable</h2>

      {/* Description */}
      <p className="unserviceable-description">
        We don’t have any services here till now. Try changing location.
      </p>
    </div>
  );
};

export default LocationUnserviceable;
