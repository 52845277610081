import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {selectCurrentUser, selectCurrentToken} from "./auth/authSlice";
import axios from "axios";

const cartState = {
    //    updateState: false,
    //    loading: false,
    cartList: [],
    cartCount: 0,
    wishListCount: 0,
    cartAmount: 0,
    deliverySlot: [],
    takeAwaySlot: [],
    deliveryAddress: [],
    timeSlot: [],
    cartListRemoved: [],
    cartListNew: [],
    freeProducts: [],
    outOfStock: false,
    placeOrderStatus: false,
    totalDiscount: 0,
    subTotal: 0,
    orderId: 0,
    error: "",
    response: "",
    cartStatus: false,
};

const changeCart = () => {

}

export const addCart = createAsyncThunk(
        "cart/addCart",
        async (data, {getState}) => {

    if (getState().cart.cartStatus === true) {
//        console.log("Trigger");
//        console.log(getState().cart.cartListNew);
        const user = getState().auth.user;
//        console.log(user);
        const token = getState().auth.token;
        const response = await axios.post(process.env.REACT_APP_CART_BASE_URL + `/cart/add/`,
                {
                    "tempID": user.name,
                    "customerId": user.customerId,
                    "cart": [...getState().cart.cartListNew, ...getState().cart.cartListRemoved]
                }, {headers: {Authorization: token}});
        console.log("Trigger RR");
        return response.data;
}

}
);
export const fetchCart = createAsyncThunk(
        "cart/fetchCart",
        async (data, {getState}) => {
    //    const product = getState().product;
    const user = getState().auth.user;
    const token = getState().auth.token;
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    if (token) {
        const response = await axios.get(process.env.REACT_APP_CART_BASE_URL + `/cart/get/?branchId=${branch.id}&tempID=` + user.name, {headers: {Authorization: token}});
        return response.data;
    }

    //  console.log(response.data);
    return [];
}
);
export const fetchDeliverySlot = createAsyncThunk(
        "delivery/getSlots",
        async (data) => {
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    const response = await axios.get(process.env.REACT_APP_BASE_URL + `/delivery/getSlots?branchId=${branch.id}`);
    //  console.log(response.data);
    return response.data;
}
);
export const checkCartStatus = createAsyncThunk(
        "cart/checkCartStatus",
        async (data, {getState}) => {
    //    const product = getState().product;
    const user = getState().auth.user;
    const token = getState().auth.token;
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    if (token) {
        const response = await axios.get(process.env.REACT_APP_CART_BASE_URL + `/cart/check/?branchId=${branch.id}&tempID=` + user.name, {headers: {Authorization: token}});
        return response.data;
    }

    //  console.log(response.data);
    return [];
}
);
export const fetchTakeawaySlot = createAsyncThunk(
        "delivery/getTakeawaySlots",
        async (data) => {
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    const response = await axios.get(process.env.REACT_APP_BASE_URL + `/takeAway/getSlots?branchId=${branch.id}`);
    //  console.log(response.data);
    return response.data;
}
);
export const fetchDeliveryAddress = createAsyncThunk(
        "delivery/getAddress",
        async (data, {getState}) => {
    const token = getState().auth.token;
    const response = await axios.get(process.env.REACT_APP_CUSTOMER_BASE_URL + `/customer/getAddress`, {headers: {Authorization: token}});
    //  console.log(response.data);
    return response.data;
}
);

export const placeOrder = createAsyncThunk(
        "checkout/placeorder",
        async (data, {getState}) => {
    const token = getState().auth.token;
    const response = await axios.post(process.env.REACT_APP_ORDER_BASE_URL + `/orders/checkout`, data, {headers: {Authorization: token}});
    //  console.log(response.data);
    return response.data;
}
);


const cartSlice = createSlice({
    name: "cart",
    initialState: cartState,
    reducers: {
        changeStateTrue: (state) => {
            state.updateState = true;
        },
        changeStateFalse: (state) => {
            state.updateState = false;
        },
        clearResponse: (state) => {
            state.response = "";
        },
        clearStock: (state) => {
            state.outOfStock = false;
        },
        addToCart: (state, action) => {
            if (state.cartListNew.length > 0) {
                var exists = state.cartListNew.some(el => el.productItemId === action.payload.productItemId);
                if (exists)
                {
                    state.cartListNew = state.cartListNew.map((item) => {
                        if (item.productItemId === action.payload.productItemId) {
                            item.quantity += 1;
                            item.totalQuantity += 1;
                        }
                        return item;
                    });
                } else {
                    state.cartListNew.push(action.payload);
                }
            } else {
                state.cartListNew.push(action.payload);
            }
            let cartTotalList = [];
            let num = 0;
            state.totalDiscount = 0;
            let cartProducts = JSON.parse(JSON.stringify(state.cartListNew.filter(el => el.productItemId > 0)));
//            console.log(cartProducts);
            cartProducts.forEach((cp, index) => {
                let cps = {};
                let free = {};
                let extra = {};
                var mrp = parseFloat(cp.mrpPrice).toFixed(2);
                var sprice = parseFloat(cp.salesPrice).toFixed(2);
                cps.productItemId = cp.productItemId;
                cps.productName = cp.productName;
                cps.image = cp.image;
                cps.quantity = cp.quantity;
                cps.totalQuantity = cp.quantity;
                cps.stock = cp.stock;
                cps.offer_type = cp.offer_type;
                cps.value_type = cp.value_type;
                cps.offer_Value = cp.offer_Value;
                cps.offer_quantity = cp.offer_quantity;
                cps.offer_products_id = cp.offer_products_id;
                cps.offer_free_quantity = cp.offer_free_quantity;
                cps.mrpPrice = mrp;
                cps.salesPrice = sprice;
                cps.badge = "";
                cps.status = 1;
                if (cp.offer_type === 3) {
                    if (cp.value_type === 0) {
                        cps.price = (mrp - ((cp.offer_Value / 100) * mrp)).toFixed(2);
                        cps.badge = cp.offer_Value + "%";
                    }
                    if (cp.value_type === 1) {
                        cps.price = (mrp - cp.offer_Value).toFixed(2);
                        cps.badge = "₹" + cp.offer_Value;
                    }
//                                    console.log((cart.mrpPrice - cart.price) * cart.quantity);
                    state.totalDiscount += (mrp - cps.price) * cp.quantity;
                } else if (cp.offer_type === 4) {
                    cps.price = mrp;
                    cps.badge = "BOGO";
                } else if (cp.offer_type === 5) {
                    if (cp.quantity >= cp.offer_quantity) {
                        cps.badge = "Free";
                        cps.price = mrp;
                        cps.free_product = cp.offer_products_id;
                        let x = Math.floor(cp.quantity / cp.offer_quantity);
                        cps.offer_product_quantity = x;
                        var offPro = cartProducts.filter(function (el) {
                            return el.offer_products_id == cp.free_product;
                        });
                        if (offPro.length > 0) {
                            free.productName = offPro[0].productName;
                            free.image = offPro[0].image;
                            free.offer_products_id = offPro[0].offer_products_id;
                            free.price = 0;
                            free.mrpPrice = 0;
                            free.badge = "Free Product";
                            free.quantity = x * cp.offer_free_quantity;
                            free.totalQuantity = x * cp.offer_free_quantity;
                            free.status = 1;
                        }
                        if ((cp.quantity % cp.offer_quantity) > 0) {
                            let difference = (cp.quantity % cp.offer_quantity);
                            extra.productName = cp.productName;
                            extra.image = cp.image;
                            extra.price = sprice;
                            extra.mrpPrice = mrp;
                            extra.badge = "";
                            extra.quantity = difference;
                            extra.totalQuantity = difference;
                            cps.totalQuantity -= difference;
                        }
                    } else {
                        cps.price = sprice;
                    }
                } else {
                    cps.price = sprice;
                }
                cartTotalList.push(cps);
                if (cp.offer_type === 5 && free.price == 0) {
                    cartTotalList.push(free);
                }
                if (extra.price) {
                    num += extra.quantity * extra.price;
                    cartTotalList.push(extra);
                }
//                                cps.totalQuantity = cp.quantity;
                num += cps.totalQuantity * cps.price;
            });
//            state.cartListStatus=state.cartListNew;
            state.cartListNew = cartTotalList;
            state.cartCount = state.cartListNew.filter(el => el.productItemId > 0).length;

//            var num = state.cartListNew.reduce(function (a, b) {
//                return (a + (b['quantity'] * b['price']));
//            }, 0);
            state.cartAmount = parseFloat(num).toFixed(2);
            state.cartStatus = true;
        },
        removeFromCart: (state, action) => {
            if (state.cartListNew.length > 0) {
                var exists = state.cartListNew.some(el => el.productItemId === action.payload.productItemId);
                if (exists)
                {
//                     console.log(action.payload.itemId);
                    state.cartListNew.map((item) => {
                        if (item.productItemId === action.payload.productItemId) {
                            if (action.payload.quantity === 0) {
                                item.quantity = 0;
                                item.totalQuantity = 0;
                            } else {
                                item.quantity -= 1;
                                item.totalQuantity -= 1;

                            }
                            return item;

                        }
                    });
                    state.cartListRemoved = state.cartListNew.filter(el => el.quantity == 0);
                    state.cartListNew = state.cartListNew.filter(el => el.quantity !== 0);
                }

                let cartTotalList = [];
                let num = 0;
                state.totalDiscount = 0;
                let cartProducts = JSON.parse(JSON.stringify(state.cartListNew.filter(el => el.productItemId > 0)));
//            console.log(cartProducts);
                cartProducts.forEach((cp, index) => {
                    let cps = {};
                    let free = {};
                    let extra = {};
                    var mrp = parseFloat(cp.mrpPrice).toFixed(2);
                    var sprice = parseFloat(cp.salesPrice).toFixed(2);
                    cps.productItemId = cp.productItemId;
                    cps.productName = cp.productName;
                    cps.image = cp.image;
                    cps.quantity = cp.quantity;
                    cps.totalQuantity = cp.quantity;
                    cps.stock = cp.stock;
                    cps.offer_type = cp.offer_type;
                    cps.value_type = cp.value_type;
                    cps.offer_Value = cp.offer_Value;
                    cps.offer_quantity = cp.offer_quantity;
                    cps.offer_products_id = cp.offer_products_id;
                    cps.offer_free_quantity = cp.offer_free_quantity;
                    cps.mrpPrice = mrp;
                    cps.salesPrice = sprice;
                    cps.badge = "";
                    cps.status = 1;
                    if (cp.offer_type === 3) {
                        if (cp.value_type === 0) {
                            cps.price = (mrp - ((cp.offer_Value / 100) * mrp)).toFixed(2);
                            cps.badge = cp.offer_Value + "%";
                        }
                        if (cp.value_type === 1) {
                            cps.price = (mrp - cp.offer_Value).toFixed(2);
                            cps.badge = "₹" + cp.offer_Value;
                        }
//                                    console.log((cart.mrpPrice - cart.price) * cart.quantity);
                        state.totalDiscount += (mrp - cps.price) * cp.quantity;
                    } else if (cp.offer_type === 4) {
                        cps.price = mrp;
                        cps.badge = "BOGO";
                    } else if (cp.offer_type === 5) {
                        if (cp.quantity >= cp.offer_quantity) {
                            cps.badge = "Free";
                            cps.price = mrp;
                            cps.free_product = cp.offer_products_id;
                            let x = Math.floor(cp.quantity / cp.offer_quantity);
                            cps.offer_product_quantity = x;
                            var offPro = cartProducts.filter(function (el) {
                                return el.offer_products_id == cp.free_product;
                            });
                            if (offPro.length > 0) {
                                free.productName = offPro[0].productName;
                                free.image = offPro[0].image;
                                free.offer_products_id = offPro[0].offer_products_id;
                                free.price = 0;
                                free.mrpPrice = 0;
                                free.badge = "Free Product";
                                free.quantity = x * cp.offer_free_quantity;
                                free.totalQuantity = x * cp.offer_free_quantity;
                                free.status = 1;
                            }
                            if ((cp.quantity % cp.offer_quantity) > 0) {
                                let difference = (cp.quantity % cp.offer_quantity);
                                extra.productName = cp.productName;
                                extra.image = cp.image;
                                extra.price = sprice;
                                extra.mrpPrice = mrp;
                                extra.badge = "";
                                extra.quantity = difference;
                                extra.totalQuantity = difference;
                                cps.totalQuantity -= difference;
                            }
                        } else {
                            cps.price = sprice;
                        }
                    } else {
                        cps.price = sprice;
                    }
                    cartTotalList.push(cps);
                    if (cp.offer_type === 5 && free.price == 0) {
                        cartTotalList.push(free);
                    }
                    if (extra.price) {
                        num += extra.quantity * extra.price;
                        cartTotalList.push(extra);
                    }
//                                cps.totalQuantity = cp.quantity;
                    num += cps.totalQuantity * cps.price;
                });
//            state.cartListStatus=state.cartListNew;
                state.cartListNew = cartTotalList;


                state.cartCount = state.cartListNew.filter(el => el.productItemId > 0).length;
//                let num = state.cartListNew.reduce(function (a, b) {
//                    return (a + (b['quantity'] * b['price']));
//                }, 0);
                state.cartAmount = parseFloat(num).toFixed(2);
                state.cartStatus = true;
            }
        },
        clearState: (state) => {
            state.cartList = [];
            state.cartListNew = [];
            state.cartCount = 0;
            state.totalDiscount = 0;
            state.subTotal = 0;
            state.wishListCount = 0;
            state.cartAmount = 0;
            state.deliverySlot = [];
            state.deliveryAddress = [];
            state.timeSlot = [];
            state.error = "";
            state.response = "";
        }
    },
    extraReducers: (builder) => {
        //        builder
        //                .addCase(addEmployee.pending, (state) => {
        //                    state.loading = true;
        //                })
        //                .addCase(addEmployee.fulfilled, (state, action) => {
        //                    state.loading = false;
        //                    state.employeeList.push(action.payload);
        //                    state.response = "add";
        //                })
        //                .addCase(addEmployee.rejected, (state, action) => {
        //                    state.loading = false;
        //                    state.error = action.error.message;
        //                });

        builder
                .addCase(addCart.fulfilled, (state, action) => {
                    state.cartStatus = false;
                })
                .addCase(addCart.rejected, (state, action) => {
                    state.error = action.error.message;
                });
        builder
                .addCase(fetchCart.fulfilled, (state, action) => {
                    //                    console.log(action);
                    if (action.payload.status == "SUCCESS") {
                        var cartTotalList = [];
                        let num = 0;
                        var offerFreeProducts = action.payload.data.freeProducts;
                        var cartProducts = action.payload.data.cartList;
                        state.cartCount = action.payload.data.cartList.length;
                        state.wishListCount = action.payload.data.wishListCount || 0;
                        if (cartProducts.length > 0) {
                            cartProducts.forEach((cp, index) => {
                                let cps = {};
                                let free = {};
                                let extra = {};
                                cps.productItemId = cp.productItemId;
                                cps.productName = cp.productName;
                                cps.image = cp.image;
                                cps.quantity = cp.quantity;
                                cps.totalQuantity = cp.quantity;
                                cps.stock = cp.stock;
                                cps.offer_type = cp.offer_type;
                                cps.value_type = cp.value_type;
                                cps.offer_Value = cp.offer_Value;
                                cps.offer_quantity = cp.offer_quantity;
                                cps.offer_products_id = cp.offer_products_id;
                                cps.offer_free_quantity = cp.offer_free_quantity;
                                cps.mrpPrice = cp.mrpPrice.toFixed(2);
                                cps.salesPrice = cp.price.toFixed(2);
                                cps.badge = "";
                                cps.status = 1;
                                if (cp.offer_type === 3) {
                                    if (cp.value_type === 0) {
                                        cps.price = (cp.mrpPrice - ((cp.offer_Value / 100) * cp.mrpPrice)).toFixed(2);
                                        cps.badge = cp.offer_Value + "%";
                                    }
                                    if (cp.value_type === 1) {
                                        cps.price = (cp.mrpPrice - cp.offer_Value).toFixed(2);
                                        cps.badge = "₹" + cp.offer_Value;
                                    }
//                                    console.log((cart.mrpPrice - cart.price) * cart.quantity);
                                    state.totalDiscount += (cp.mrpPrice - cps.price) * cp.quantity;
                                } else if (cp.offer_type === 4) {
                                    cps.price = cp.mrpPrice.toFixed(2);
                                    cps.badge = "BOGO";
                                } else if (cp.offer_type === 5) {
                                    if (cp.quantity >= cp.offer_quantity) {
                                        cps.badge = "Free";
                                        cps.price = cp.mrpPrice.toFixed(2);
                                        cps.free_product = cp.offer_products_id;
                                        let x = Math.floor(cp.quantity / cp.offer_quantity);
                                        cps.offer_product_quantity = x;
                                        var offPro = offerFreeProducts.filter(function (el) {
                                            return el.offer_products_id == cp.offer_products_id;
                                        });
                                        if (offPro.length > 0) {
                                            free.productName = offPro[0].ProductName;
                                            free.image = offPro[0].image[0];
                                            free.offer_products_id = offPro[0].offer_products_id;
                                            free.price = 0;
                                            free.mrpPrice = 0;
                                            free.badge = "Free Product";
                                            free.quantity = x * cp.offer_free_quantity;
                                            free.totalQuantity = x * cp.offer_free_quantity;
                                            free.status = 1;
                                        }
                                        if ((cp.quantity % cp.offer_quantity) > 0) {
                                            let difference = (cp.quantity % cp.offer_quantity);
                                            extra.productName = cp.productName;
                                            extra.image = cp.image;
                                            extra.price = cp.price.toFixed(2);
                                            extra.mrpPrice = cp.mrpPrice.toFixed(2);
                                            extra.badge = "";
                                            extra.quantity = difference;
                                            extra.totalQuantity = difference;
                                            cps.totalQuantity -= difference;
                                        }
                                    } else {
                                        cps.price = cp.price.toFixed(2);
                                    }
                                } else {
                                    cps.price = cp.price.toFixed(2);
                                }
                                cartTotalList.push(cps);
                                if (cp.offer_type === 5 && free.price == 0) {
                                    cartTotalList.push(free);
                                }
                                if (extra.price) {
                                    num += extra.quantity * extra.price;
                                    cartTotalList.push(extra);
                                }
//                                cps.totalQuantity = cp.quantity;
                                num += cps.totalQuantity * cps.price;
                            });
                            state.cartListNew = cartTotalList;
//                            state.freeProducts = action.payload.data.freeProducts;
//                            let num = cartTotalList.reduce(function (a, b) {
//                                return (a + (b['quantity'] * b['price']));
//                            }, 0);
                            state.cartAmount = parseFloat(num).toFixed(2);
                            state.subTotal = parseFloat(state.totalDiscount) + parseFloat(state.cartAmount);
                        }
                    }
                })
                .addCase(fetchCart.rejected, (state, action) => {
                    state.error = action.error.message;
                });
        builder
                .addCase(fetchDeliverySlot.fulfilled, (state, action) => {
                    state.deliverySlot = action.payload.data;
                    state.timeSlot = Object.values(action.payload.data)[0];
                })
                .addCase(fetchDeliverySlot.rejected, (state, action) => {
                    state.error = action.error.message;
                });
        builder
                .addCase(checkCartStatus.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        if (action.payload.data.outofStock > 0) {
                            state.outOfStock = true;
                        }
                    } else {
                        state.error = "error";
                    }
                })
                .addCase(checkCartStatus.rejected, (state, action) => {
                    state.error = action.error.message;
                });
        builder
                .addCase(fetchTakeawaySlot.fulfilled, (state, action) => {
                    state.deliverySlot = action.payload.data;
                    state.timeSlot = Object.values(action.payload.data)[0];
                })
                .addCase(fetchTakeawaySlot.rejected, (state, action) => {
                    state.error = action.error.message;
                });
        builder
                .addCase(fetchDeliveryAddress.fulfilled, (state, action) => {
                    //                    console.log(action);
                    state.deliveryAddress = action.payload.data.result;
                })
                .addCase(fetchDeliveryAddress.rejected, (state, action) => {
                    console.log(action.error);
                    state.error = action.error.message;
                });
        builder
                .addCase(placeOrder.fulfilled, (state, action) => {
//                    console.log(action.payload.success);
                    if (action.payload.status == "SUCCESS") {
                        state.placeOrderStatus = true;
                        state.orderId = action.payload.data.orderId;
                        state.cartCount = 0;
                        state.cartAmount = 0;
                    } else {
                        state.error = action.payload.message;
                    }
//                    state.deliveryAddress = action.payload.data.result;
                })
                .addCase(placeOrder.rejected, (state, action) => {
                    console.log(action.error);
                    state.error = action.error.message;
                });

        //        builder.addCase(removeEmployee.fulfilled, (state, action) => {
        //            state.employeeList = state.employeeList.filter(
        //                    (item) => item._id != action.payload
        //            );
        //            state.response = "delete";
        //        });
        //
        //        builder.addCase(modifiedEmployee.fulfilled, (state, action) => {
        //            const updateItem = action.payload;
        //            console.log(updateItem);
        //            const index = state.employeeList.findIndex(
        //                    (item) => item._id === updateItem._id
        //            );
        //            if (index !== -1) {
        //                state.employeeList[index] = updateItem;
        //            }
        //            state.response = "update";
        //        });
    },
});

export default cartSlice.reducer;
export const {changeStateTrue, changeStateFalse, clearResponse, clearState, addToCart, removeFromCart, updateCart, clearStock} =
        cartSlice.actions;


