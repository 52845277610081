/* 
 * Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
 * Click nbfs://nbhost/SystemFileSystem/Templates/ClientSide/javascript.js to edit this template
 */


import { useState, useEffect }
from "react";
import {  useParams, useLocation }
from 'react-router-dom';

import {Container, Row, Col, Tab, Nav }
from 'react-bootstrap';
import { library }
from '@fortawesome/fontawesome-svg-core';
import { fas }
from '@fortawesome/free-solid-svg-icons';
import { fab }
from '@fortawesome/free-brands-svg-icons';
import { far }
from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }
from '@fortawesome/react-fontawesome';

library.add(fas, fab, far);
function Payments() {

    return (
            <div>
                <Row><h2>Payment Details</h2></Row>
                <br/>
                <Row>
                    <p>
                    <b>How do I make a payment for Grace Super Market purchase?</b><br/>
                        We have option for payment that is cash on Delivery and Card payment options.<br/><br/>
            
                        <b>Are there any hidden charges when I make a purchase on Grace Super Market (GST )?</b><br/>
                        There are absolutely no hidden charges when you make a purchase with Grace Super Market. We offer free delivery if the order is Rs. 1000 or over.The prices listed for all items are final and all-inclusive. The prices you see on our product pages are exactly what you pay for the item.<br/><br/>
            
                        <b>What is Cash on Delivery?</b><br/>
                        Cash-on-Delivery (C-O-D) is an alternate method of purchasing from us, other than Online (By Credit/Debit card and Netbanking). In the C-O-D payment terms or C-O-D service, you pay at the time of the actual delivery, rather than paying in advance.<br/><br/>
            
                        <b>What is card payment option?</b><br/>
            
                        You can purchase products from online and pay using Debit/Credit card through online.Also you can pay through Netbanking while purchasing the products from online.<br/><br/>
            
            
            
                        <b>Are there any hidden terms and conditions for a C-O-D purchase?</b><br/>
                        The maximum order value for a C-O-D payment is Rs. 10,000. It's strictly a cash-only payment method; Please note, we do not accept foreign currency as payment against a COD order.<br/><br/>
            
                        <b>Placing a Cash-on-Delivery (C-O-D) Order</b><br/>
                        All items that have the "Cash-on-Delivery Available" Icon are valid for order by Cash-on-Delivery. Add the item(s) to your cart and proceed to checkout.<br/>
            
                        When prompted to choose a Payment Option, select "Pay By Cash-on-Delivery"<br/>
            
                        Once verified and confirmed and be processed for shipment in the time specified, from the date of confirmation.<br/>
            
                        You will be required to make a Cash payment to our Delivery person, only at the time of delivery of your order.<br/><br/>
            
                        <b>Terms & Conditions</b><br/>
                        The maximum order value for C-O-D is Rs. 10,000<br/>
            
                        Please make ONLY CASH PAYMENT at the time of delivery.<br/><br/>
            
                        <b>Contact Us</b><br/>
                        Couldn't find the information you needed? Please contact us<br/><br/>
                    </p>
                </Row>
            </div>

            );
}

export default Payments;