import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import ProductCard from "../product/product";
import OwlCarousel from "react-owl-carousel";
function HomeProductList({ data, title = null, productList = null }) {
  const [list, setList] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      let retdata = data.map((item) => {
        let resultsFiltered = productList.find((i) => {
          return i.action_id === item.actionID;
        });
        // return resultsFiltered;
        let responseObj = {
          ...resultsFiltered,
        };

        responseObj["variations"] = [
          {
            ...resultsFiltered,
          },
        ];
        return responseObj;
      });
      setList(retdata);
    }
  }, []);
  // console.log(list,'LIST PRODUCTS');
  return (
    <>
      <Row>
        <h5 className="dealsTxt mt-4">{title}</h5>
        {list.length > 0 && (
          <OwlCarousel
            className="owl-theme px-0 mt-0 mt-sm-3"
            autoplay
            margin={10}
            items={5}
            dots={false}
            autoplayHoverPause={true}
            responsive={{
              0: { items: 1 },
              400: { items: 1 },
              600: { items: 1 },
              700: { items: 3 },
              1000: { items: 5 },
            }}
          >
            {list.map(
              (product, index) =>
                product.variations.length > 0 && (
                  <ProductCard
                    product={product}
                    index={index}
                    type="owl"
                    key={index}
                  />
                )
            )}
          </OwlCarousel>
        )}
      </Row>
    </>
  );
}
export default HomeProductList;
