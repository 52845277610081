import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Form, Row, Col,Image } from "react-bootstrap";
import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
  Marker,
} from "@react-google-maps/api";
import "../static/css/App.css";
import axios from "axios";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectCurrentToken,
  logOut,
} from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
library.add(fas, fab, far);
const containerStyle = {
  width: "100%",
  height: "100%",
};
const options = {
  streetViewControl: false,
  mapTypeControl: false,
};
const libraries = ["places"];
const GoogleMapModal = ({
  showModal,
  handleCloseModal,
  onLocationSelect,
  availBranches,
  edit,
  editAddress,
}) => {
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  // const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [userName, setUserName] = useState("");
  const [doorFlatNo, setDoorFlatNo] = useState("");
  const [address, setAddress] = useState("");
  const [landmark, setLandmark] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState("");
  const [errors, setErrors] = useState({});
  const [savedAddresses, setSavedAddresses] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [locationSelected, setLocationSelected] = useState(false);
  const [addressId, setAddressId] = useState(null);
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [addressType, setAddressType] = useState(1);

  const mapRef = useRef(null);
  const autocompleteRef = useRef(null);
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const token = useSelector(selectCurrentToken);
  const isLoggedIn = !!token;
  // const isLoggedIn = !!user;
  // const isTokenExist = !!token;

  // Load Google Maps API
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCl_C3ax0icW8_Q_FvHaqvSnsDxgZYBbM4",
    libraries,
  });
  useEffect(() => {
    if (showModal) {
      setAddress("");
      setDoorFlatNo("");
      setLandmark("");
      setErrors({});
      setLocationSelected(false);
      setButtonDisabled("");
      if (isLoggedIn) {
        setUserName(user.name);
        setMobileNo(user.mobile);
        getSavedAddress();
      }
    }
  }, [showModal]);
  const handlePlaceChanged = async () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setLocation({ lat, lng });
        setLocationSelected(true);
        updateMarkerAndAddress(lat, lng);
        if (!isLoggedIn) {
          await fetchBranchesByLatLng(lat, lng);
        }
      }
    }
  };
  const updateMarkerAndAddress = (lat, lng) => {
    setLocation({ lat, lng });
    getAddressFromLatLng(lat, lng);
  };
  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({
          lat: latitude,
          lng: longitude,
        });
        setLocationSelected(true);
        updateMarkerAndAddress(latitude, longitude);
        if (!isLoggedIn) {
          await fetchBranchesByLatLng(latitude, longitude);
        }
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  const getAddressFromLatLng = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(lat, lng);
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          const formattedAddress = results[0].formatted_address;
          const addressComponents = results[0].address_components;
          console.log(addressComponents);
          let area = "";
          let city = "";
          let state = "";
          let country = "";
          let pincode = "";
          addressComponents.forEach((component) => {
            if (
              component.types.includes("sublocality_level_1") ||
              component.types.includes("locality")
            ) {
              area = component.long_name; // Area or city
            }
            if (component.types.includes("administrative_area_level_2")) {
              city = component.long_name; // City
            }
            if (component.types.includes("administrative_area_level_1")) {
              state = component.long_name; // State
            }
            if (component.types.includes("country")) {
              country = component.long_name; // Country
            }
            if (component.types.includes("postal_code")) {
              pincode = component.long_name; // Pincode
            }
          });
          if (!isLoggedIn) {
            onLocationSelect({ area: area, pincode: pincode });
            localStorage.setItem(
              "selectedLocation",
              JSON.stringify({
                area: area,
                pincode: pincode,
                lat: lat,
                lng: lng,
              })
            );
          }
          setAddress(formattedAddress);
          setArea(area);
          setCity(city);
          setState(state);
          setCountry(country);
          setPincode(pincode);
        } else {
          console.error("No results found");
        }
      } else {
        console.error("Geocoder failed due to: " + status);
      }
    });
  };
  const handleMapDragEnd = () => {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter();
      const lat = newCenter.lat();
      const lng = newCenter.lng();
      setLocation({ lat, lng });
      getAddressFromLatLng(lat, lng);
    }
  };
  // Validate inputs and show error messages
  const validateInputs = () => {
    const newErrors = {};
    if (!userName) {
      newErrors.userName = "Name cannot be empty";
    }
    const mobileNoRegex = /^[0-9]{10}$/;
    if (!mobileNo) {
      newErrors.mobileNo = "Mobile No. cannot be empty";
    } else if (!mobileNoRegex.test(mobileNo)) {
      newErrors.mobileNo =
        "Invalid Mobile No. It should have at least 10 digits.";
    }
    //        if (!doorFlatNo) {
    //            newErrors.doorFlatNo = 'Door/Flat No cannot be empty';
    //        }
    if (!landmark) {
      newErrors.landmark = "Landmark cannot be empty";
    }
    if (!address) {
      newErrors.address = "Address cannot be empty";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // GET Address API
  const getSavedAddress = async () => {
    try {
      if (!token) {
        throw new Error("No token found. Please log in.");
      }
      const config = {
        headers: {
          Authorization: token,
        },
      };
      let addressUrl =
        process.env.REACT_APP_CUSTOMER_BASE_URL + "/customer/getAddress/";
      const response = await axios.get(addressUrl, config);
      // console.log('Get saved Address:', response.data.data.result);
      setSavedAddresses(response.data.data.result);
    } catch (error) {
      console.error("Error fetching getSavedAddress:", error);
    }
  };
  // Get Branch API by Lat&Lng
  const fetchBranchesByLatLng = async (latitude, longitude) => {
    try {
      let brchUrl = process.env.REACT_APP_BASE_URL + "/branches/getBylatLong";
      const response = await axios.post(brchUrl, {
        latitude: latitude,
        longitude: longitude,
      });
      const branch = response.data.data[0];
      if (branch) {
        //                localStorage.setItem('availableBranches', JSON.stringify(response.data.data));
        localStorage.setItem("selectedBranch", JSON.stringify(branch));
        availBranches(response.data.data);
        navigate("/");
        handleCloseModal();
        return branch;
      } else {
        //                localStorage.removeItem('availableBranches');
        localStorage.removeItem("selectedBranch");
        availBranches([]);
        navigate("/location-unserviceable");
        handleCloseModal();
        return null;
      }
    } catch (error) {
      console.error("Error fetching branches:", error);
      return null;
    }
  };
  // POST Address API
  const handleSaveAddress = async () => {
    if (validateInputs()) {
      const payload = {
        address: address,
        name: userName,
        mobile: mobileNo,
        doorFlatNo: doorFlatNo,
        landmark: landmark,
        area: area,
        city: city,
        state: state,
        country: country,
        pincode: pincode,
        latitude: location.lat.toString(),
        longitude: location.lng.toString(),
        addressType: addressType,
      };
      try {
        if (!token) {
          throw new Error("No token found. Please log in.");
        }
        setButtonDisabled(true);
        const config = {
          headers: {
            Authorization: token,
          },
        };
        if (!edit) {
          let saveAddressUrl =
            process.env.REACT_APP_CUSTOMER_BASE_URL + "/customer/saveAddress/";
          const response = await axios.post(saveAddressUrl, payload, config);
          console.log(response.data.data.result.id);
          if (response.data.status == "SUCCESS") {
            setDefaultAddress(response.data.data.result.id);
            localStorage.setItem(
              "selectedAddress",
              JSON.stringify({ address: response.data.data.result.id })
            );
            localStorage.setItem(
              "selectedLocation",
              JSON.stringify({
                area: area,
                pincode: pincode,
                lat: location.lat,
                lng: location.lng,
              })
            );
          }
        } else {
          let upAddressUrl =
            process.env.REACT_APP_CUSTOMER_BASE_URL +
            `/customer/updateAddress/${editAddress.id}`;
          const response = await axios.post(upAddressUrl, payload, config);
        }
        handleCloseModal();
      } catch (error) {
        console.error("Error saving address:", error);
      }
    }
  };
  const onSaveAddress = async () => {
    if (edit) {
      await handleSaveAddress();
    } else {
      onLocationSelect({ area: area, pincode: pincode });
      if (location && location.lat && location.lng) {
        const branch = await fetchBranchesByLatLng(
          location.lat.toString(),
          location.lng.toString()
        );
        if (branch) {
          await handleSaveAddress();
          navigate("/");
        }
      }
    }
  };

  useEffect(() => {
    if (edit && editAddress) {
      setLocationSelected(true);
      setAddress(editAddress.address);
      setDoorFlatNo("");
      setLocation({
        lat: parseFloat(editAddress.latitude),
        lng: parseFloat(editAddress.longitude),
      });
      getAddressFromLatLng(
        parseFloat(editAddress.latitude),
        parseFloat(editAddress.longitude)
      );
      setLandmark(editAddress.landmark);
      setUserName(editAddress.name);
      setMobileNo(editAddress.mobile);
      setAddressType(editAddress.addressTypeId ?? 1);
    }
  }, [edit]);
  useEffect(() => {
    if (addressId) {
      setDefaultAddress(addressId);
      localStorage.setItem(
        "selectedAddress",
        JSON.stringify({ address: addressId })
      );
      localStorage.setItem(
        "selectedLocation",
        JSON.stringify({
          area: area,
          pincode: pincode,
          lat: location.lat,
          lng: location.lng,
        })
      );
    }
  }, [addressId]);
  useEffect(() => {
    const address = JSON.parse(localStorage.getItem("selectedAddress"));
    if (address && address.address) {
      setDefaultAddress(address.address);
      setShippingAddress(
        savedAddresses.sort((a) => (a.id == address.address ? -1 : 1))
      );
    } else {
      setShippingAddress(savedAddresses);
    }
  }, [savedAddresses]);
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center">
          {locationSelected && !edit && (
            <FontAwesomeIcon
              icon="fa-solid fa-arrow-left"
              className="arrow-icon"
              style={{
                marginRight: "10px",
                cursor: "pointer",
                color: "#7e7e7e",
              }}
              onClick={() => setLocationSelected(false)}
            />
          )}
          {/* {isLoggedIn ? "Set Delivery Location" : "Choose Location"} */}
          <Image src={process.env.REACT_APP_IMAGE_URL+'/home/gsmlogo.png'} alt="GSM Logo" className="w-50"/>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0" style={{ maxHeight: "75vh", overflowY: "auto" }}>
      
        {isLoggedIn ? (
          <div>
            {!locationSelected ? (
              <div>
                <Autocomplete
                  onLoad={(ref) => (autocompleteRef.current = ref)}
                  onPlaceChanged={handlePlaceChanged}
                >
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="location-searchbox"
                      placeholder="Search for area, street name..."
                    />
                  </Form.Group>
                </Autocomplete>
                <Button
                  className="get-location-btn mt-3 text-light"
                  onClick={handleGetCurrentLocation}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-location-crosshairs"
                    className="location-icon"
                  />
                  <span> Use current location</span>
                </Button>
                <div className="saved-addresses mt-4 border-0">
                  <h6 style={{ color: "#ef4438", textAlign: "center" }}>
                    SAVED ADDRESSES
                  </h6>
                  <Row>
                    {shippingAddress.map((address, index) => (
                      <div
                        key={index}
                        className={`address-item mt-1 col-md-6 pt-1  ${
                          defaultAddress == address.id ? "active" : ""
                        }`}
                        onClick={() => {
                          fetchBranchesByLatLng(
                            address.latitude,
                            address.longitude
                          );
                          onLocationSelect({
                            area: address.area,
                            pincode: address.pincode,
                          });
                          setAddressId(address.id);
                          setArea(address.area);
                          setPincode(address.pincode);
                          setLocation({
                            lat: address.latitude,
                            lng: address.longitude,
                          });
                        }}
                      >
                        <div className="col-12 p-2 rounded address-header border h-100">
                          <h6 className="mb-0">{address.name}</h6>
                          <p className="address-text mb-1">
                            {address.address}, {address.area}, {address.city},{" "}
                            {address.state}{" "}
                            {address.pincode ? address.pincode : ""},{" "}
                            {address.country}
                          </p>
                          {address.landmark && (
                            <small>
                              {" "}
                              <b>Landmark</b> : {address.landmark},{" "}
                            </small>
                          )}
                          {address.mobile && (
                            <small>
                              <b>Mobile</b> : {address.mobile}
                            </small>
                          )}
                        </div>
                      </div>
                    ))}
                  </Row>
                </div>
              </div>
            ) : (
              <Row>
                <Col md={6}>
                  {isLoaded && (
                    <div style={{ height: "100%", width: "100%" }}>
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={location}
                        zoom={15}
                        onLoad={(map) => (mapRef.current = map)}
                        onDragEnd={handleMapDragEnd}
                        options={options}
                      >
                        {location && (
                          <Marker
                            key={`${location.lat}-${location.lng}`}
                            position={location}
                            draggable={true}
                            onDragEnd={(e) => {
                              const lat = e.latLng.lat();
                              const lng = e.latLng.lng();
                              setLocation({ lat, lng });
                              getAddressFromLatLng(lat, lng);
                            }}
                          />
                        )}
                      </GoogleMap>
                    </div>
                  )}
                </Col>

                <Col md={6}>
                  <div>
                    <Form.Group className="mt-0">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter address"
                        value={address}
                        style={{ resize: "none" }}
                        onChange={(e) => setAddress(e.target.value)}
                        readOnly
                      />
                      {errors.address && (
                        <div className="text-danger mt-1">{errors.address}</div>
                      )}
                    </Form.Group>

                    <Form.Group className="mt-3">
                      <Form.Control
                        type="text"
                        placeholder="Landmark"
                        value={landmark}
                        onChange={(e) => setLandmark(e.target.value)}
                      />
                      {errors.landmark && (
                        <div className="text-danger mt-1">
                          {errors.landmark}
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Control
                        type="text"
                        placeholder="Your Name"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      {errors.userName && (
                        <div className="text-danger mt-1">
                          {errors.userName}
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Control
                        type="text"
                        placeholder="Mobile Number"
                        value={mobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                      />
                      {errors.mobileNo && (
                        <div className="text-danger mt-1">
                          {errors.mobileNo}
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Address Type</Form.Label>
                      <div key={`inline-radio`} className="mb-3">
                        <Form.Check
                          inline
                          label="Home"
                          name="addrtype"
                          type="radio"
                          {...(addressType == "1" && { checked: true })}
                          id={`inline-radio-1`}
                          onChange={() => setAddressType(1)}
                        />
                        <Form.Check
                          inline
                          label="Office"
                          name="addrtype"
                          type="radio"
                          {...(addressType == "2" && { checked: true })}
                          onChange={() => setAddressType(2)}
                          id={`inline-radio-2`}
                        />
                        <Form.Check
                          inline
                          label="Others"
                          name="addrtype"
                          type="radio"
                          {...(addressType == "3" && { checked: true })}
                          onChange={() => setAddressType(3)}
                          id={`inline-radio-3`}
                        />
                      </div>
                    </Form.Group>
                    <Button
                      className="mt-3"
                      variant="secondary"
                      style={{ width: "100%" }}
                      onClick={onSaveAddress}
                      disabled={buttonDisabled}
                    >
                      Save Address & Proceed
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        ) : (
          <div>
            <Autocomplete
              onLoad={(ref) => (autocompleteRef.current = ref)}
              onPlaceChanged={handlePlaceChanged}
            >
              <Form.Group>
                <Form.Control
                  type="text"
                  className="location-searchbox"
                  placeholder="Search for area, street name..."
                />
              </Form.Group>
            </Autocomplete>
            <Button
              className="get-location-btn mt-3 text-light"
              onClick={handleGetCurrentLocation}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-location-crosshairs"
                className="location-icon"
              />
              <span> Use current location</span>
            </Button>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default GoogleMapModal;

{
  /*
 <Form.Group className="mt-3">
 <Form.Control
 type="text"
 placeholder="Door / Flat No."
 value={doorFlatNo}
 onChange={(e) => setDoorFlatNo(e.target.value)}
 />
 {errors.doorFlatNo && (
 <div className="text-danger mt-1">{errors.doorFlatNo}</div>
 )}
 </Form.Group>
 */
}
