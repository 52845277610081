import { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ToastContainer,
  Toast,
} from "react-bootstrap";

import {
  selectCurrentUser,
  selectCurrentToken,
  logOut,
} from "../features/auth/authSlice";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../static/css/App.css";

library.add(fas, fab, far);
function TrackOrder() {
  const [orderId, setOrderId] = useState(null);
  const [toast, setToast] = useState(false);
  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken);
  const handleTrack = () => {
    if (token) {
      if (orderId) {
        navigate(`/profile/order/${orderId}`);
      }
    } else {
      setToast(true);
    }
  };
  return (
    <div>
      <Container fluid="xl"  className="px-md-5 px-3 px-xl-3">
        <Row>
          <h2>Track Order</h2>
        </Row>
        <Row>
          <Form className="mt-4 mb-5">
            <Form.Group
              as={Row}
              className="mb-3 justify-content-center"
              controlId="formHorizontalEmail"
            >
              <Form.Label column sm={1}>
                <b>Order Id:</b>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  type="text"
                  placeholder="Order Id"
                  onChange={(e) => setOrderId(e.target.value)}
                />
              </Col>
              <Col sm={2}>
                <Button type="button" onClick={() => handleTrack()}>
                  Track
                </Button>
              </Col>
            </Form.Group>
          </Form>
          <ToastContainer
            className="p-3"
            position="bottom-center"
            style={{ zIndex: 5 }}
          >
            <Toast
              className="d-inline-block m-1"
              bg="danger"
              key={1}
              onClose={() => setToast(false)}
              show={toast}
              delay={5000}
              autohide
            >
              <Toast.Body className="text-white">
                Please login to track the order
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </Row>
      </Container>
    </div>
  );
}

export default TrackOrder;
