import { NavLink } from "react-router-dom";
import { Row, Carousel } from "react-bootstrap";
function Banners({ data }) {
  return (
    <Row>
      <Carousel indicators={false} className="BICarousel">
        {data.map((bnr,idx) => (
          <Carousel.Item interval={1000} key={idx}>
            <NavLink to={bnr.url} className="cursor-pointer">
              <img
                className="d-block w-100"
                src={process.env.REACT_APP_IMAGE_URL + bnr.valueData}
                alt={`Home banner ${bnr.id} `}
              />
            </NavLink>
          </Carousel.Item>
        ))}
      </Carousel>
    </Row>
  );
}
export default Banners;
