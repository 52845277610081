const UserIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0002 5.7245C12.2288 5.7245 9.14306 8.81574 9.14306 12.5939C9.14306 16.3721 12.2288 19.4633 16.0002 19.4633C19.7716 19.4633 22.8573 16.3721 22.8573 12.5939C22.8573 8.81574 19.7716 5.7245 16.0002 5.7245ZM16.0002 17.1735C13.4859 17.1735 11.4288 15.1127 11.4288 12.5939C11.4288 10.0751 13.4859 8.0143 16.0002 8.0143C18.5145 8.0143 20.5716 10.0751 20.5716 12.5939C20.5716 15.1127 18.5145 17.1735 16.0002 17.1735Z"
        fill="#363636"
      />
      <path
        d="M16 0C7.14318 0 0 7.15596 0 16.0286C0 20.3792 1.77143 24.3864 4.57143 27.2487C5.25714 27.9356 6 28.5653 6.85714 29.1377C9.42857 30.9696 12.5714 32 16 32C19.4286 32 22.5711 30.9123 25.1429 29.1377C25.9429 28.5653 26.6857 27.9356 27.4286 27.2487C30.2857 24.3292 32 20.3792 32 16.0286C32 7.15596 24.8568 0 16 0ZM16 29.7674C12.5714 29.7674 9.48571 28.508 7.08571 26.4472C7.71429 24.3864 9.6 22.898 11.8286 22.898H20.2282C22.4568 22.898 24.3425 24.3864 24.9711 26.4472C22.514 28.508 19.4289 29.7674 16 29.7674ZM26.6854 24.6154C25.4854 22.2111 23.0282 20.6082 20.2282 20.6082H11.8286C8.97143 20.6082 6.51429 22.2683 5.37143 24.6154C3.42857 22.2683 2.28571 19.2916 2.28571 16.0286C2.28571 8.47227 8.45714 2.2898 16 2.2898C23.5429 2.2898 29.7143 8.47227 29.7143 16.0286C29.7143 19.2916 28.5711 22.2683 26.6854 24.6154Z"
        fill="#363636"
      />
    </svg>
  );
};
const CartIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.9769 5.15837H32.4957C33.144 5.16038 33.758 5.43605 34.1728 5.91151C34.5839 6.38205 34.7505 7.00437 34.6265 7.60551L32.9693 16.3581C32.8832 16.8089 32.6429 17.2199 32.2858 17.5263C31.9288 17.833 31.4751 18.0179 30.9962 18.0522L12.1733 19.1439L12.5285 20.6875V20.6872C12.6121 21.0602 12.9574 21.327 13.3573 21.3272H30.6611H30.6609C31.1075 21.2794 31.5542 21.4154 31.8892 21.7011C32.2241 21.9868 32.4157 22.3951 32.4157 22.8236C32.4157 23.2524 32.2241 23.6607 31.8892 23.9464C31.5542 24.2321 31.1075 24.368 30.6609 24.3203H13.3181C12.3948 24.3379 11.4945 24.0462 10.7734 23.4959C10.0523 22.9457 9.55653 22.1716 9.37192 21.3084L5.42592 3.63365C5.34216 3.26066 4.99705 2.99416 4.59718 2.99371H1.55875C1.14345 2.98879 0.74708 2.82786 0.455318 2.54594C0.163551 2.26425 0 1.88389 0 1.48789C0 1.09347 0.164252 0.715147 0.456486 0.436348C0.748955 0.157555 1.14531 0.000857991 1.55872 0.000857991H4.59715C5.5176 -0.0178917 6.41582 0.271171 7.13651 0.817827C7.85714 1.36425 8.35483 2.13411 8.54339 2.99371L9.03661 5.17718L8.9769 5.15837ZM15.4285 32C14.7167 32 14.0342 31.7304 13.5309 31.2502C13.0279 30.7701 12.745 30.119 12.745 29.4402C12.745 28.7612 13.0279 28.1101 13.5309 27.6299C14.0342 27.1498 14.7167 26.8801 15.4285 26.8801C16.14 26.8801 16.8227 27.1498 17.3258 27.6299C17.8291 28.11 18.1117 28.7612 18.1117 29.4402C18.1117 30.119 17.8291 30.7701 17.3258 31.2502C16.8227 31.7304 16.14 32 15.4285 32ZM27.9573 32C27.2455 32 26.563 31.7304 26.0597 31.2502C25.5564 30.7701 25.2738 30.119 25.2738 29.4402C25.2738 28.7612 25.5564 28.1101 26.0597 27.6299C26.563 27.1498 27.2455 26.8801 27.9573 26.8801C28.6688 26.8801 29.3513 27.1498 29.8546 27.6299C30.3579 28.11 30.6405 28.7612 30.6405 29.4402C30.6405 30.119 30.3579 30.7701 29.8546 31.2502C29.3513 31.7304 28.6688 32 27.9573 32Z"
        fill="#363636"
      />
    </svg>
  );
};
const WhatsappIconSolid = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0278 3.00617C10.0831 -9.93766e-05 6.11283 -0.893856 3.03381 0.974908C0.0358244 2.84367 -0.936496 6.9062 1.00815 9.91248L1.1702 10.1562L0.521985 12.5937L2.95279 11.9437L3.19587 12.1062C4.24921 12.675 5.38359 13 6.51796 13C7.73336 13 8.94876 12.675 10.0021 12.025C13.0001 10.075 13.8914 6.0937 12.0278 3.00617ZM10.3262 9.26247C10.0021 9.74998 9.59698 10.075 9.02979 10.1562C8.70568 10.1562 8.30055 10.3187 6.68002 9.66872C5.30256 9.01872 4.16819 7.96246 3.35792 6.7437C2.87176 6.17495 2.62868 5.44369 2.54765 4.71244C2.54765 4.06243 2.79073 3.49368 3.19587 3.08742C3.35792 2.92492 3.51997 2.84367 3.68203 2.84367H4.08716C4.24921 2.84367 4.41127 2.84367 4.49229 3.16868C4.65435 3.57493 5.05948 4.54994 5.05948 4.63119C5.14051 4.71244 5.14051 4.87494 5.05948 4.95619C5.14051 5.11869 5.05948 5.28119 4.97846 5.36244C4.89743 5.44369 4.8164 5.60619 4.73537 5.68744C4.57332 5.76869 4.49229 5.9312 4.57332 6.0937C4.89743 6.5812 5.30256 7.0687 5.7077 7.47496C6.19386 7.88121 6.68002 8.20621 7.2472 8.44996C7.40926 8.53122 7.57131 8.53122 7.65234 8.36872C7.73336 8.20621 8.1385 7.79996 8.30055 7.63746C8.4626 7.47496 8.54363 7.47496 8.70568 7.55621L10.0021 8.20621C10.1642 8.28746 10.3262 8.36871 10.4072 8.44996C10.4883 8.69372 10.4883 9.01872 10.3262 9.26247Z"
        fill="white"
      />
    </svg>
  );
};

export { UserIcon, CartIcon,WhatsappIconSolid };
