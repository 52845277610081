import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import ProductCard from "../product/product";
import OwlCarousel from "react-owl-carousel";
function BannerProducts({
  homerow,
  bannerImg,
  url,
  title = null,
  productList = null,
}) {
  const [list, setList] = useState([]);
  const [listTwo, setListTwo] = useState([]);
  const [sizeType, setSizeType] = useState("");

  useEffect(() => {
    // console.log(homerow[0].homedata, "productList");
    if (homerow[0].homedata.length > 0) {
      let retdata = homerow[0].homedata.map((item) => {
        let resultsFiltered = productList.find((i) => {
          return i.action_id === item.actionID;
        });
        // return resultsFiltered;
        let responseObj = {
          ...resultsFiltered,
        };
        responseObj["variations"] = [
          {
            ...resultsFiltered,
          },
        ];
        return responseObj;
      });
      setList(retdata);
    }
    if (homerow[1].homedata.length > 0) {
      //   console.log(retdata,'listTwo');
      let retdatatwo = homerow[1].homedata.map((item) => {
        let resultsFiltered = productList.find((i) => {
          return i.action_id === item.actionID;
        });
        // return resultsFiltered;
        let responseObj = {
          ...resultsFiltered,
        };
        responseObj["variations"] = [
          {
            ...resultsFiltered,
          },
        ];
        return responseObj;
      });
      setListTwo(retdatatwo);
      setSizeType("half");
    }
  }, []);
  // console.log(listTwo,'LIST Two PRODUCTS');
  return (
    <>
      <Row>
        <Col md={4} lg={3} className="px-0 d-none d-sm-block">
          <NavLink to={url}>
            <img
              className="d-inline-block h-100 w-100"
              src={process.env.REACT_APP_IMAGE_URL + bannerImg}
              alt="sidebanner for products"
            />
          </NavLink>
        </Col>
        <Col md={8} lg={9} xs={12} className="pe-0">
          {list.length > 0 && (
            <Row>
              <OwlCarousel
                className="owl-theme px-0"
                autoplay
                margin={5}
                items={3}
                dots={false}
                responsive={{
                  0: { items: 1 },
                  400: { items: 1 },
                  600: { items: 1 },
                  700: { items: 3 },
                  1000: { items: 3 },
                }}
              >
                {list.length > 0 &&
                  list.map(
                    (product, index) =>
                      product.variations.length > 0 && (
                        <ProductCard
                          product={product}
                          index={index}
                          type="owl"
                          key={index}
                          size={sizeType}
                        />
                      )
                  )}
              </OwlCarousel>
            </Row>
          )}
          {listTwo.length > 0 && (
            <Row>
              <OwlCarousel
                className="owl-theme px-0"
                autoplay
                margin={5}
                items={3}
                dots={false}
                responsive={{
                  0: { items: 1 },
                  400: { items: 1 },
                  600: { items: 1 },
                  700: { items: 3 },
                  1000: { items: 3 },
                }}
              >
                {listTwo.map(
                  (product, index) =>
                    product.variations.length > 0 && (
                      <ProductCard
                        product={product}
                        index={index}
                        key={index}
                        type="owl"
                        size={sizeType}
                      />
                    )
                )}
              </OwlCarousel>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
}
export default BannerProducts;
