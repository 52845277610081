import { useDispatch, useSelector }
from "react-redux";
import { useState, useEffect }
from "react";
import { Row, Col } from "react-bootstrap";
// import { useGetRecentPurchaseQuery } from "../../features/ordersApi";
import ProductCard from "../product/product";
import OwlCarousel from "react-owl-carousel";
import category from "../../static/images/homepage/careprobanner.png";
import { fetchRecentPurchaseList } from "../../features/productSlice";
        function RecentPurchase() {
        const dispatch = useDispatch();
                const { recentPurchaseListProduct } = useSelector((state) => state.product);
                useEffect(() => {
                dispatch(fetchRecentPurchaseList());
                        }, [dispatch]);
                return (
                        <Row>

    {recentPurchaseListProduct.length > 0 && (
                                <>
    <h5 className="dealsTxt mt-4">Products from previous basket</h5>
    <OwlCarousel
        className="owl-theme px-0 mt-3"
        autoplay
        margin={10}
        items={5}
        dots={false}
        autoplayHoverPause={true}
        responsive={{0: {items: 1}, 400: {items: 1}, 600: {items: 1}, 700: {items: 3}, 1000: {items: 5}}}
        >
        {recentPurchaseListProduct.map((product, index) =>
                                product.variations.length > 0 && index <= 10 ? (
                                        <ProductCard
            product={product}
            index={index}
            type="owl"
            key={index}
            />
                                        ) : (
                                ""
                                )
                                )}
    </OwlCarousel>
    </>
    )}
</Row>
                                );
}

export default RecentPurchase;
