import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBranchList } from "../features/commonSlice";

import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../static/css/App.css";

library.add(fas, fab, far);
function ContactUs() {
  const dispatch = useDispatch();
  const { branchList } = useSelector((state) => state.common);
  useEffect(() => {
    dispatch(fetchBranchList());
    window.scrollTo(0, 0);
  }, [dispatch]);
  return (
    <div>
      <Container fluid="xl" className="px-md-5 px-3 px-xl-3">
        <Row>
          <h2>Contact Us</h2>
        </Row>
        <Row>
          <Form className="">
            <Form.Group
              as={Row}
              className="mb-3 justify-content-center"
              controlId="formHorizontalEmail"
            >
              <Form.Label column sm={1}>
                <b>Name</b>
              </Form.Label>
              <Col sm={4}>
                <Form.Control type="text" placeholder="Name" />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3 justify-content-center"
              controlId="formHorizontalEmail"
            >
              <Form.Label column sm={1}>
                <b>Email</b>
              </Form.Label>
              <Col sm={4}>
                <Form.Control type="email" placeholder="Email address" />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3 justify-content-center"
              controlId="formHorizontalEmail"
            >
              <Form.Label column sm={1}>
                <b>Mobile No</b>
              </Form.Label>
              <Col sm={4}>
                <Form.Control type="number" size="sm"  className="w-100 text-start" placeholder="Mobile No" />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3 justify-content-center"
              controlId="formHorizontalEmail"
            >
              <Form.Label column sm={1}>
                <b>Location</b>
              </Form.Label>
              <Col sm={4}>
                <Form.Control type="text" placeholder="Location" />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3 justify-content-center"
              controlId="formHorizontalEmail"
            >
              <Form.Label column sm={1}>
                <b>Inquiry For</b>
              </Form.Label>
              <Col sm={4}>
                <Form.Select aria-label="Default select example">
                  <option value="1">General Inquiry</option>
                  <option value="2">Product Inquiry</option>
                  <option value="3">Complaints</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3 justify-content-center"
              controlId="formHorizontalEmail"
            >
              <Form.Label column sm={1}>
                <b>Message</b>
              </Form.Label>
              <Col sm={4}>
                <Form.Control
                  as="textarea"
                  rows={5}
                  type="text"
                  placeholder="Your Query"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col sm={{ span: 6, offset: 6 }}>
                <Button type="button">Submit</Button>
              </Col>
            </Form.Group>
          </Form>
        </Row>
        <Row>
          <h2>Our Branches</h2>
        </Row>
        <Row>
          {branchList &&
            branchList.map((bl, index) => (
              <Col sm="3" className="my-3">
                <Card className="h-100">
                  <Card.Body className="p-2 lh-lg text-wrap">
                    <p className="text-primary mb-0">
                      <strong>
                        {index + 1}. {bl.branchName}
                      </strong>
                    </p>
                    {bl.address}
                    <br />
                    {bl.areaName},{bl.city}-{bl.pincode}
                    <br />
                    <strong>Mobile No:</strong> {bl.mobile}
                    <br />
                    <strong>Phone No:</strong>
                    {bl.phone},{bl.alternativePhone}
                    <br />
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
}

export default ContactUs;
