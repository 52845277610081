import React, { useState, useEffect }
from "react";
import "./price.css";

const CatlogPriceFilter = ({min, max,setPriceFilter}) => {
    const initialMinPrice = min;
    const initialMaxPrice = max;
    const [sliderMinValue,setSliderMinValue] = useState(initialMinPrice);
    const [sliderMaxValue,setSliderMaxValue] = useState(initialMaxPrice);
    const [minVal, setMinVal] = useState(initialMinPrice);
    const [maxVal, setMaxVal] = useState(initialMaxPrice);
    const [minInput, setMinInput] = useState(initialMinPrice);
    const [maxInput, setMaxInput] = useState(initialMaxPrice);
    const [isDragging, setIsDragging] = useState(false);
    const minGap = 5;
    useEffect(() => {
        setSliderTrack();
    }, [minVal, maxVal]);
    useEffect(() => {
        setSliderMinValue(min);
        setSliderMaxValue(max);
        setMinVal(min);
        setMaxVal(max);
        setMinInput(min);
        setMaxInput(max);
    }, [min, max]);
    const slideMin = (e) => {
        const value = parseFloat(e.target.value);
        if (value >= sliderMinValue && maxVal - value >= minGap) {
            setMinVal(value);
            setMinInput(value);
        }
    };
    const slideMax = (e) => {
        const value = parseFloat(e.target.value, 10);
        if (value <= sliderMaxValue && value - minVal >= minGap) {
            setMaxVal(value);
            setMaxInput(value);
        }
    };
    const setSliderTrack = () => {
        const range = document.querySelector(".slider-track");
        if (range) {
            const minPercent =
                    ((minVal - sliderMinValue) / (sliderMaxValue - sliderMinValue)) * 100;
            const maxPercent =
                    ((maxVal - sliderMinValue) / (sliderMaxValue - sliderMinValue)) * 100;
            range.style.left = `${minPercent}%`;
            range.style.right = `${100 - maxPercent}%`;
        }
    };
    const handleMinInput = (e) => {
        const value =
                e.target.value === "" ? sliderMinValue : parseFloat(e.target.value);
        if (value >= sliderMinValue && value < maxVal - minGap) {
            setMinInput(value);
            setMinVal(value);
        }
//        handleInputKeyDown(e, "min");
    };
    const handleMaxInput = (e) => {
        const value =
                e.target.value === "" ? sliderMaxValue : parseFloat(e.target.value);
        if (value <= sliderMaxValue && value > minVal + minGap) {
            setMaxInput(value);
            setMaxVal(value);
        }
    };
    const handleInputKeyDown = (e, type) => {
        if (e.key === "Enter") {
            const value = parseFloat(e.target.value);
            if (
                    type === "min" &&
                    value >= sliderMinValue &&
                    value < maxVal - minGap
                    ) {
                setMinVal(value);
            } else if (
                    type === "max" &&
                    value <= sliderMaxValue &&
                    value > minVal + minGap
                    ) {
                setMaxVal(value);
            }
        }
    };
    const startDrag = () => {
        setIsDragging(true);
    };
    const stopDrag = () => {
        setIsDragging(false);     
        setPriceFilter(`${minVal},${maxVal}`);
    };
    return (
            <div className="double-slider-box"> 
            
                <div className="range-slider">
                    <div className="slider-track"></div>
                    <input
                        type="range"
                        min={sliderMinValue}
                        max={sliderMaxValue}
                        value={minVal}
                        onChange={slideMin}
                        onMouseDown={startDrag}
                        onMouseUp={stopDrag}
                        onTouchStart={startDrag}
                        onTouchEnd={stopDrag}
                        className="min-val"
                        step=".1"
                        />
                    <input
                        type="range"
                        min={sliderMinValue}
                        max={sliderMaxValue}
                        value={maxVal}
                        onChange={slideMax}
                        onMouseDown={startDrag}
                        onMouseUp={stopDrag}
                        onTouchStart={startDrag}
                        onTouchEnd={stopDrag}
                        className="max-val"
                        step=".1"
                        />
                    <div className="min-tooltip">{minVal}</div>
                    <div className="max-tooltip">{maxVal}</div>
                </div>
            </div>
            );
};
export default CatlogPriceFilter;