import { fetchMyOrders } from "../../features/profileSlice";
import { useDispatch, useSelector }
from "react-redux";
import { useState, useEffect }
from "react";
import {  NavLink }
from 'react-router-dom';
import { Container, Row, Col, Image, Breadcrumb, Table, Card, ListGroup, Tab, Tabs, Nav, Button, Toast, ToastContainer, Alert}
from 'react-bootstrap';
import { library }
from '@fortawesome/fontawesome-svg-core';
import { fas }
from '@fortawesome/free-solid-svg-icons';
import { fab }
from '@fortawesome/free-brands-svg-icons';
import { far }
from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }
from '@fortawesome/react-fontawesome';
import '../../static/css/App.css';
import moment from 'moment';
library.add(fas, fab, far);

function MyOrders() {

    const dispatch = useDispatch();
    const {myOrders, orderStatus} = useSelector(
            (state) => state.profile
    );
    useEffect(() => {
        dispatch(fetchMyOrders());
    }, [dispatch]);
    useEffect(()=>{
        console.log(myOrders);
    },[myOrders]);
    return (
            <div>
                <Container fluid className="">
                    <Row>
                        {myOrders && myOrders.length > 0 && myOrders.map((order) =>
                                <Col md="6" className="py-2">
                                <Card className="order-card">                   
                                <Card.Body>
                                    <Card.Title className="text-warning">{orderStatus[order.order_status]}</Card.Title>
                                    <Card.Text>
                                        <Row>
                                            <div className="col-8"><b>Order Id #{order.order_showid}</b></div>
                                            <div className="col-8 text-muted placed-date">Order Placed on {moment(order.order_posteddate, "YYYY-MM-DD").format("DD/MM/YYYY")}</div>
                                            <div className={"col-4 text-end myorder-img-sec ps-0 " + (order.pro_images && order.pro_images.length > 3 ? 'pe-0' : 'pe-3')}>
                                                {order.pro_images && order.pro_images.slice(0, 3).map((img) =>
                                                            <Image className="myorders-img" roundedCircle src={`https://gracesupermarket.s3.ap-southeast-2.amazonaws.com/uploads/${img.image}`}/>
                                                                )}
                                                {order.pro_images && order.pro_images.length > 3 &&
                                                                <span className="myorders-more">+{order.pro_images.length - 3}</span> }
                    
                                            </div>
                                        </Row>                                
                                    </Card.Text>                                    
                                </Card.Body>
                                <Card.Footer className="">
                                    Total <FontAwesomeIcon icon="fa-solid fa-indian-rupee-sign" size="xs" />{order.order_totalamnt}
                                    <Button variant="primary" className="d-block float-end" size="sm">
                                        <NavLink to={`/profile/order/${order.order_showid}`}>Order Details</NavLink>
                                    </Button>
                                </Card.Footer>
                                </Card>
                                </Col>)}                        
                    </Row>
                </Container>
            </div>
            );
}




export default MyOrders;

