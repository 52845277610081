import { apiSlice } from "../../app/api/apiSlice";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
//        baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_CUSTOMER_BASE_URL }),
            login: builder.mutation({
                query: credentials => ({
                        url: '/customer/signin',
                        method: 'POST',
                        body: {...credentials}
                    })
            }),
            signup: builder.mutation({
                query: data => ({
                        url: '/customer/signup',
                        method: 'POST',
                        body: {...data}
                    })
            }),
            sendotp: builder.mutation({
                query: data => ({
                        url: '/customer/sendOTP',
                        method: 'POST',
                        body: {...data}
                    })
            }),
        })
})



export const {
    useLoginMutation,
    useSignupMutation,
    useSendotpMutation,
} = authApiSlice