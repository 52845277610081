import logo from "../static/images/e-logo.png";
import banner from "../static/images/banner.png";
import product from "../static/images/product.png";
import productBanner from "../static/images/p_banner.png";
import product2Banner from "../static/images/product-2.png";
import category from "../static/images/category.png";
import brand from "../static/images/brand.png";
import downBanner from "../static/images/down_banner.png";
import fruits from "../static/images/fruits.png";
import oils from "../static/images/oils.png";
import mobile from "../static/images/mobile.png";
import { NavLink } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Model,
  Stack,
  Form,
  Badge,
  Nav,
  Navbar,
  NavDropdown,
  Carousel,
  Card,
  InputGroup,
  ListGroup,
} from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../static/css/App.css";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

library.add(fas, fab, far);
function Footer() {
  return (
    <Container fluid className="bg-body-tertiary">
      <Container>
        <Row className="pt-5">
          <Col md="4">
            <h5>Contact us (Head Office)</h5>
            <p className="mt-4">
              No:278, 3rd Floor, Arcot Road, Vadapalani,
              <br />
              Chennai - 600026
            </p>
            <p>
              <b> customercare@gracesupermarket.com </b>
            </p>
            <p>
              <b>6033360333</b>
            </p>
          </Col>
          <Col md="2" className="footer-info">
            <h5>Information</h5>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <NavLink
                  to="/about-us"
                  className="text-decoration-none text-dark"
                >
                  About us
                </NavLink>
              </ListGroup.Item>
              <ListGroup.Item>
                <NavLink
                  to="/delivery-info"
                  className="text-decoration-none text-dark"
                >
                  Delivery information
                </NavLink>
              </ListGroup.Item>
              <ListGroup.Item>
                <NavLink
                  to="/privacy"
                  className="text-decoration-none text-dark"
                >
                  Privacy policy
                </NavLink>
              </ListGroup.Item>
              <ListGroup.Item>
                <NavLink
                  to="/terms-conditions"
                  className="text-decoration-none text-dark"
                >
                  Terms & Conditions
                </NavLink>
              </ListGroup.Item>
              <ListGroup.Item>
                <NavLink to="/faq" className="text-decoration-none text-dark">
                  FAQs
                </NavLink>
              </ListGroup.Item>
            </ListGroup>
          </Col>

          <Col md="2" className="footer-info">
            <h5>Customer Service</h5>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <NavLink
                  to="/contact"
                  className="text-decoration-none text-dark"
                >
                  Contact us
                </NavLink>
              </ListGroup.Item>
              <ListGroup.Item>
                <NavLink
                  to="/contact"
                  className="text-decoration-none text-dark"
                >
                  Feedback
                </NavLink>
              </ListGroup.Item>
              <ListGroup.Item>
                <NavLink
                  to="/trackorder"
                  className="text-decoration-none text-dark"
                >
                  Track Package
                </NavLink>
              </ListGroup.Item>
              <ListGroup.Item>
                <NavLink
                  to="/payments"
                  className="text-decoration-none text-dark"
                >
                  Payment
                </NavLink>
              </ListGroup.Item>
              <ListGroup.Item>
                <NavLink
                  to="/cancellation-returns"
                  className="text-decoration-none text-dark"
                >
                  Cancellation & Returns
                </NavLink>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md="2" className="footer-info">
            <h5>Other links</h5>
            <ListGroup variant="flush">
              <ListGroup.Item>Categories</ListGroup.Item>
              <ListGroup.Item>
                <NavLink
                  to="/brands"
                  className="text-decoration-none text-dark"
                >
                  Brands
                </NavLink>
              </ListGroup.Item>
              <ListGroup.Item>Testimonials</ListGroup.Item>
              <ListGroup.Item>Blog</ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md="2" className="footer-info">
            <h5>Follow us on</h5>
            <ListGroup horizontal>
              <ListGroup.Item
                action
                href={process.env.REACT_APP_FACEBOOK_LINK}
                target="_blank"
              >
                <FontAwesomeIcon
                  icon="fa-brands fa-facebook"
                  size="2xl"
                  style={{ color: "#000080" }}
                />
              </ListGroup.Item>
              <ListGroup.Item
                action
                href={process.env.REACT_APP_YOUTUBE_LINK}
                target="_blank"
              >
                <FontAwesomeIcon
                  icon="fa-brands fa-youtube"
                  size="2xl"
                  style={{ color: "#ff0000" }}
                />
              </ListGroup.Item>
              <ListGroup.Item
                action
                href={process.env.REACT_APP_INSTAGRAM_LINK}
                target="_blank"
              >
                <FontAwesomeIcon
                  icon="fa-brands fa-instagram"
                  size="2xl"
                  style={{ color: "#d6249f" }}
                />
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
      <Row>
        <Col
          className="text-center py-2 text-white fw-bold"
          style={{ background: "#7fb63b" }}
        >
          © {new Date().getFullYear()} All Rights Reserved{" "}
          <span style={{ color: "#e7de0f" }}>Grace Supermarket</span>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
