import { useDispatch, useSelector } from "react-redux";
import {
  fetchBrandProduct,
  changeStateTrue,
  changeStateFalse,
  changeLoading,
  fetchBrandFilters,
  clearProduct,
} from "../features/productSlice";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "../components/product/product";
import CatlogPriceFilter from "../components/priceSlider/price-slider";
import dairyProducts from "../static/images/dairy_products.png";
import {
  Container,
  Row,
  Col,
  Form,
  Breadcrumb,
  Dropdown,
} from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../static/css/App.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
library.add(fas, fab, far);
function BrandProduct() {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [priceFilter, setPriceFilter] = useState(null);
  const [maxPriceFilter, setMaxPriceFilter] = useState(null);
  const [brandSlug, setBrandSlug] = useState(null);
  //                const[categoryId, setCategoryId] = useState(null);
  const dispatch = useDispatch();
  const {
    brandProductList,
    error,
    response,
    completed,
    breadCrumb,
    filterCategories,
    filterBrands,
    minPrice,
    maxPrice,
  } = useSelector((state) => state.product);
  const fetchData = () => {
    setIsLoading(true);
    if (brandSlug) {
      dispatch(fetchBrandProduct({ brand: brandSlug, price: priceFilter }));
    }
  };
  useEffect(() => {
    setBrandSlug(slug);
    dispatch(fetchBrandFilters({ brand: slug }));
    window.scrollTo(0, 0);
  }, [slug]);
  useEffect(() => {
    dispatch(changeLoading());
    fetchData();
    dispatch(changeStateTrue());
  }, [brandSlug]);
  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 20 && !isLoading) {
        fetchData();
        dispatch(changeStateTrue());
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [slug, isLoading]);
  useEffect(() => {
    if (completed) {
      setIsLoading(false);
    }
  }, [completed]);
  useEffect(() => {
    dispatch(clearProduct());
    fetchData();
    dispatch(changeStateTrue());
  }, [priceFilter]);
  return (
    <div>
      <Container  fluid="xl"  className="px-md-5 px-3 px-xl-3">
        <Row>
          {breadCrumb != null && (
            <Breadcrumb>
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              {breadCrumb.brands && (
                <Breadcrumb.Item active>
                  {breadCrumb.brands.brandName}
                </Breadcrumb.Item>
              )}
            </Breadcrumb>
          )}
        </Row>
        <Row>
          <img src={dairyProducts} alt="Category banner" />
        </Row>
        <Row className="my-4">
          <Col md="3">
            <h1 className="fs-4">
              {filterBrands.length > 0 && filterBrands[0].brandName}{" "}
              <span className="text-secondary" style={{ fontSize: "12px" }}>
                (34 Products)
              </span>
            </h1>
          </Col>
          <Col md="4">
            <label className="float-start" style={{ paddingLeft: "5%" }}>
              Price Range
            </label>
            {minPrice && maxPrice && (
              <CatlogPriceFilter
                min={minPrice}
                max={maxPrice}
                setPriceFilter={setPriceFilter}
              />
            )}
          </Col>
          <Col md="5" className="text-end">
            <Dropdown className="d-inline mx-2" autoClose="inside">
              <Dropdown.Toggle
                className="product-filters  text-white"
                id="dropdown-autoclose-inside"
              >
                Default Sorting
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="button">Default Sorting</Dropdown.Item>
                <Dropdown.Item as="button">Price High</Dropdown.Item>
                <Dropdown.Item as="button">Price Low</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>

      <Container  fluid="xl"  className="px-md-5 px-3 px-xl-3">
        <Row className="my-5">
          {brandProductList &&
            brandProductList.map((product, index) =>
              product.variations.length > 0 ? (
                <ProductCard product={product} index={index} />
              ) : (
                ""
              )
            )}
        </Row>
      </Container>
    </div>
  );
}

export default BrandProduct;

{
  /*<!--<Dropdown className="d-inline mx-2" autoClose="outside">
 <Dropdown.Toggle className="product-filters  text-white" id="dropdown-autoclose-outside">
 Categories
 </Dropdown.Toggle>
 
 <Dropdown.Menu>
 {filterCategories && filterCategories.length > 0 && filterCategories.map((fc, index) => (
 <Dropdown.Item as="button" key={index}>
 <Form.Check
 type="checkbox"
 id={`d-procheckbox-${index}`}
 label={fc.categoryName}
 value={fc.categoryID}
 />
 </Dropdown.Item>
 ))}
 </Dropdown.Menu>
 </Dropdown>*/
}
