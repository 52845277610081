import { useState, useEffect } from "react";

import OwlCarousel from "react-owl-carousel";

import { Row, Col, Card, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
function BrandList({ brandList }) {
  return (
    <Row>
      <div className="mt-2">
        <h3 className="px-0 py-3 dealsTxt">Shop by brands</h3>
        <OwlCarousel
          className="owl-theme px-0"
          autoplay={false}
          margin={10}
          items={5}
          dots={false}
          responsive={{
            0: { items: 1 },
            400: { items: 1 },
            600: { items: 2 },
            700: { items: 3 },
            1000: { items: 5 },
          }}
        >
          {brandList &&
            brandList.map((brand, index) => (
              <Col key={index} className="home-brand">
                <NavLink to={brand.url}>
                  <Card className="border-0">
                    <Card.Img
                      variant="top"
                      className="brand-img"
                      src={process.env.REACT_APP_IMAGE_URL + brand.valueData}
                    />
                  </Card>
                </NavLink>
              </Col>
            ))}
        </OwlCarousel>
      </div>
    </Row>
  );
}

export default BrandList;
