import { NavLink } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
function OfferBanners({ data, title = null }) {
  return (
    <div className=" py-3 pt-2 my-4">
      {title && <h3 className="px-0 ofTitle">{title}</h3>}
      <Row xs={1} md={3} className="g-4 float-start mb-3">
        {data.map((bnr, idx) => (
          <Col sm={3} xs={6} key={idx} className="p_banner">
            <Card className="border-0">
              <NavLink to={bnr.url}>
                <Card.Img
                  variant="top"
                  src={process.env.REACT_APP_IMAGE_URL + bnr.valueData}
                />
              </NavLink>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}
export default OfferBanners;
