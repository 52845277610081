import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useCategoryProductsQuery } from "../../features/productApi";
import ProductCard from "../product/product";
import OwlCarousel from "react-owl-carousel";

import category from "../../static/images/homepage/careprobanner.png";
function CateProducts() {
  const dispatch = useDispatch();
  const {
    data: catepro,
    isError: iscateproError,
    isLoading: iscateproLoading,
    isFetching: iscateproFetching,
    isSuccess: iscateproSuccess,
  } = useCategoryProductsQuery({ id: 551, offset: 0 });
  return (
    <div className="px-0">
      {iscateproLoading && <h2>...Loading</h2>}
      {iscateproFetching && <h2>...Fetching</h2>}
      {iscateproError && <h2>Something went wrong</h2>}
      {iscateproSuccess && (
        <Row>
          <Col md={4} lg={3} className="px-0 d-none d-sm-block">
            <NavLink to="/ct/fruit-vegetables">
              <img
                className="d-inline-block h-100 w-100"
                src={category}
                alt="Third slide"
              />
            </NavLink>
          </Col>
          <Col md={8} lg={9} xs={12} className="pe-0">
            <Row>
              <OwlCarousel
                className="owl-theme px-0"
                autoplay
                margin={5}
                items={3}
                dots={false}
                responsive={{
                  0: { items: 1 },
                  400: { items: 1 },
                  600: { items: 1 },
                  700: { items: 3 },
                  1000: { items: 3 },
                }}
              >
                {catepro &&
                  catepro.data.map((product, index) =>
                    product.variations.length > 0 && index < 5 ? (
                      <ProductCard
                        product={product}
                        index={index}
                        type="owl"
                        key={index}
                        size="half"
                      />
                    ) : (
                      ""
                    )
                  )}
              </OwlCarousel>
            </Row>
            <Row>
              <OwlCarousel
                className="owl-theme px-0"
                autoplay
                margin={5}
                items={3}
                dots={false}
                responsive={{
                  0: { items: 1 },
                  400: { items: 1 },
                  600: { items: 1 },
                  700: { items: 3 },
                  1000: { items: 3 },
                }}
              >
                {catepro &&
                  catepro.data.map((product, index) =>
                    product.variations.length > 0 &&
                    index > 5 &&
                    index <= 10 ? (
                      <ProductCard
                        product={product}
                        index={index}
                        key={index}
                        type="owl"
                        size="half"
                      />
                    ) : (
                      ""
                    )
                  )}
              </OwlCarousel>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default CateProducts;
