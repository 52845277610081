import Header from "./common/header";
import Footer from "./common/footer";
import Home from "./pages/home";
import Product from "./pages/product";
import Cart from "./pages/cart";
import Profile from "./pages/profile";
import Static from "./pages/static";
import TrackOrder from "./pages/trackorder";
import ContactUs from "./pages/contactus";
import ProductDetails from "./pages/productdetails";
import BrandProduct from "./pages/brandproduct";
import ProductGroup from "./pages/productgroup";
import BrandsListing from "./pages/brands";
import OrderDetails from "./components/profile/OrderDetails";
import Offers from "./pages/offers";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Model,
  Stack,
  Form,
  Badge,
  Nav,
  Navbar,
  NavDropdown,
  Carousel,
  Card,
  InputGroup,
  ListGroup,
} from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./static/css/App.css";
import LocationUnserviceable from "./common/LocationUnserviceable";

library.add(fas, fab, far);

function App() {
  return (
    <Router>
      <div>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ct/:slug" element={<Product />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/profile" element={<Profile />}>
            <Route path=":page" element={<Profile />} />
          </Route>
          <Route path="/about-us" element={<Static />} />
          <Route path="/vision" element={<Static />} />
          <Route path="/quality-standard" element={<Static />} />
          <Route path="/delivery-info" element={<Static />} />
          <Route path="/privacy" element={<Static />} />
          <Route path="/terms-conditions" element={<Static />} />
          <Route path="/faq" element={<Static />} />
          <Route path="/payments" element={<Static />} />
          <Route path="/cancellation-returns" element={<Static />} />
          <Route path="/trackorder" element={<TrackOrder />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/pd/:slug" element={<ProductDetails />} />
          <Route path="/brands" element={<BrandsListing />} />
          <Route path="/bd/:slug" element={<BrandProduct />} />
          <Route path="/pg/:slug" element={<ProductGroup />} />
          <Route path="/profile/order/:id" element={<OrderDetails />} />
          <Route
            path="/location-unserviceable"
            element={<LocationUnserviceable />}
          />
          <Route path="/offers" element={<Offers />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
