import { useDispatch, useSelector } from "react-redux";
import {
addCart,
        fetchCart,
        changeStateTrue,
        changeStateFalse,
        addToCart,
        removeFromCart,
        } from "../../features/cartSlice";
import {
selectCurrentUser,
        selectCurrentToken,
        logOut,
        } from "../../features/auth/authSlice";
import { useState, useEffect } from "react";
import { Col, Button, Form, Card, Badge } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Login from "../../common/login";
import Register from "../../common/register";
import {
fetchProduct,
        changeStateFalse as productClearOffset,
        } from "../../features/productSlice";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
        library.add(fas, fab, far);
        function ProductCard({ product, type, index, size: vegSize }) {
        const [addCarts, setAddCart] = useState({ itemId: "", qty: "" });
                const [cartExists, setCartExists] = useState(false);
                const [cartQty, setCartQty] = useState("");
                const [productVariant, setProductVariant] = useState(product.variations[0]);
                const [discountStatus, setDiscountStatus] = useState(false);
                const [discountValue, setDiscountValue] = useState({ type: 0, value: 0 });
                const [salesPrice, setSalesPrice] = useState(0);
                const dispatch = useDispatch();
                const { cartListNew } = useSelector((state) => state.cart);
                const handleVariationChange = (item) => {
        setAddCart.itemId = item;
        };
                const handleAddCart = (item, qty, amount) => {
        if (token) {
        dispatch(
                addToCart({
                productItemId: item,
                        quantity: 1,
                        totalQuantity: 1,
                        salesPrice: amount,
                })
                );
        } else {
        setLoginModalShow(true);
        }
        };
                const handleIncrement = (item, qty, amount) => {
        dispatch(
                addToCart({
                productItemId: item,
                        quantity: 1,
                        totalQuantity: 1,
                        price: amount,
                        status: true,
                })
                );
        };
                const handleDecrement = (item, qty, amount) => {
        //        setAddCart({itemId:item, qty:qty - 1});
        dispatch(removeFromCart({ productItemId: item, price: amount }));
        };
                const handleProductVariant = (e) => {
        var variantDet = product.variations.filter(function (el) {
        return el.productItemId == e.target.value;
        });
                setProductVariant(variantDet[0]);
        };
                const handleOfferSection = (variation) => {
        setDiscountStatus(false);
                setSalesPrice(variation.salePrice);
                if (variation.offer_type === 3) {
        setDiscountStatus(true);
                if (variation.value_type === 0) {
        setSalesPrice(
                variation.mrpPrice -
                (variation.offer_Value / 100) * variation.mrpPrice
                );
                setDiscountValue({ type: 0, value: variation.offer_Value });
        }
        if (variation.value_type === 1) {
        setSalesPrice(variation.mrpPrice - variation.offer_Value);
                setDiscountValue({ type: 1, value: variation.offer_Value });
        }
        } else if (variation.offer_type === 4) {
        setDiscountStatus(true);
                setSalesPrice(variation.mrpPrice);
                setDiscountValue({ type: 2, value: 0 });
        } else if (variation.offer_type === 5) {
        setDiscountStatus(true);
                setSalesPrice(variation.mrpPrice);
                setDiscountValue({ type: 3, value: 0 });
        }
        };
                const attributes = {
                md: "3",
                        className: "my-2",
                };
                if (type === "owl") {
        attributes.md = "";
                attributes.className = "";
        }
        let variantStyle = {
        selectBox: 7,
                buttonDiv: 5,
                buttonMargin: "ps-2",
        };
                if (vegSize === "half") {
        attributes.className = "half";
                variantStyle.selectBox = 12;
                variantStyle.buttonDiv = 12;
                variantStyle.buttonMargin = "mt-2";
        }

        const token = useSelector(selectCurrentToken);
                const [loginModalShow, setLoginModalShow] = useState(false);
                const [registerModalShow, setRegisterModalShow] = useState(false);
                const [mobileNo, setMobileNo] = useState("");
                const [otpScreen, setOtpScreen] = useState(false);
                useEffect(() => {
                if (cartListNew.length > 0) {
                var exis = cartListNew.filter(
                        (el) => el.productItemId === productVariant.productItemId
                        );
                        if (exis.length > 0) {
                setCartExists(true);
                        setCartQty(exis[0].quantity);
                } else {
                setCartExists(false);
                        setCartQty("");
                }
                } else {
                setCartExists(false);
                        setCartQty("");
                }

                handleOfferSection(productVariant);
                }, [cartListNew, productVariant]);
                // console.log('update quantity',product.variations[0].quantity);
                // console.log('index',index);

                return (
                        <Col {...attributes} key={index}>
<Card className="PCard">
<NavLink to={`/pd/${product.slug}`} className="proImage">
    <Card.Img
        variant="top"
        className="product-img"
        src={`https://gracesupermarket.s3.ap-southeast-2.amazonaws.com/uploads/${productVariant.image && productVariant.image.length > 0?productVariant.image[0]:''}`}
        />
    {discountStatus && (
                        <Badge
        bg="success"
        className="position-absolute top-0 end-0 rounded-circle offer-badge"
        >
        {discountValue.type == 0 && <div>{discountValue.value}%</div>}
        {discountValue.type == 1 && (
                                <div>
            <FontAwesomeIcon
                icon="fa-solid fa-indian-rupee-sign"
                size="xs"
                />
            {discountValue.value}
        </div>
                                )}
        {discountValue.type == 2 && <div>BOGO</div>}
        {discountValue.type == 3 && <div>Free</div>}
    </Badge>
                        )}
</NavLink>
<Card.Body>
    <Card.Title>
        <span className="price-section text-danger">
            ₹{salesPrice && salesPrice.toFixed(2)}
        </span>
        {salesPrice && salesPrice.toFixed(2) != productVariant?.mrpPrice.toFixed(2) && (
                        <span className="mrp-section text-muted">
            {" "}
            &nbsp; MRP.<s>₹{productVariant?.mrpPrice.toFixed(2)}</s>
        </span>
                        )}
    </Card.Title>
    <NavLink to={`/pd/${product.slug}`}>
        <Card.Text className="float-start w-100 mb-3 mt-2 product-name">
            <span className="product-name-section d-block text-truncate float-start">
                {product.productName && product.productName.toLowerCase()}
            </span>

            <span className="brand-section d-block text-truncate ps-2 text-muted float-start ">
                {" "}
                ({product.brandName})
            </span>
        </Card.Text>
    </NavLink>

    <Col
        md={variantStyle.selectBox}
        className="float-start"
        style={{ fontSize: "15px" }}
        >
    {product.variations.length > 1 && (
                        <Form.Select
        size="sm"
        className="product-section"
        onChange={handleProductVariant}
        >
        {product.variations.map((vari, ind) => (
                                <option value={vari.productItemId}>
            {vari.unit +
                                " " +
                                vari.unitName +
                                " - Rs." +
                                vari.salePrice.toFixed(2)}
        </option>
                                ))}
    </Form.Select>
                        )}
    {product.variations.length == 1 && (
                        <span>
        {productVariant.unit +
                        " " +
                        productVariant.unitName +
                        " - Rs." +
                        salesPrice?.toFixed(2)}
    </span>
                        )}
    </Col>
    <Col
        md={variantStyle.buttonDiv}
        className={`float-end text-end  ${variantStyle.buttonMargin}`}
        >
    {productVariant.stock > 0 && !cartExists && (
                        <Button
        variant="danger"
        className="btn-sm w-100 addcartBtn"
        onClick={() =>
                        handleAddCart(
                                productVariant.productItemId,
                                1,
                                salesPrice.toFixed(2)
                                )
                }
        >
        + Add
    </Button>
                        )}
    {productVariant.stock > 0 && cartExists && (
                        <div className="input-group variantInput">
        <div className="input-group-prepend">
            <span
                className="input-group-text cursor-pointer"
                onClick={() =>
                        handleDecrement(
                                productVariant.productItemId,
                                productVariant.quantity,
                                salesPrice.toFixed(2)
                                )
                    }
                >
                -
            </span>
        </div>
        <input
            type="text"
            className="form-control p-1 text-center"
            value={cartQty}
            disabled
            aria-label="Amount (to the nearest dollar)"
            />
        <div className="input-group-append">
            <span
                className="input-group-text cursor-pointer"
                onClick={() =>
                        handleIncrement(
                                productVariant.productItemId,
                                productVariant.quantity,
                                salesPrice.toFixed(2)
                                )
                    }
                >
                +
            </span>
        </div>
    </div>
                        )}
    </Col>
</Card.Body>
</Card>
<Login
    show={loginModalShow}
    tab={"login-mobile"}
    otpScreen={otpScreen}
    mobileNo={mobileNo}
    onHide={() => {
                setLoginModalShow(false);
                        setOtpScreen(false);
                }}
    onRegister={() => {
                setRegisterModalShow(true);
                        setLoginModalShow(false);
                        setOtpScreen(false);
                }}
    />
<Register
    show={registerModalShow}
    onHide={() => setRegisterModalShow(false)}
    mobileNo={setMobileNo}
    otpShow={() => {
                setRegisterModalShow(false);
                        setLoginModalShow(true);
                        setOtpScreen(true);
                }}
    />
</Col>

                        );
                }
        export default ProductCard;
