import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

const tmpUserObj = {
    name: Date.now().toString(36),
    customerId: null,
};

(localStorage.getItem('temp_user') ? localStorage.getItem('temp_user') : localStorage.setItem('temp_user', JSON.stringify(tmpUserObj)));
var evuser = JSON.parse(localStorage.getItem('temp_user'));

export const authState = {
    //    updateState: false,
    //    loading: false,
    user: localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token')) : evuser,
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null
};
const authSlice = createSlice({
    name: 'auth',
    initialState: authState,
    reducers: {
        setCredentials: (state, action) => {
            if (action.payload.status != "FAIL") {
                const {user, token} = action.payload.data;
                localStorage.setItem('token', token);
                (localStorage.removeItem('temp_user'));
                // console.log('Response',jwtDecode(token),'Access Token',token);
                state.user = jwtDecode(token);
                state.token = token;
            }

        },
        logOut: (state, action) => {
            state.user = null;
            state.token = null;
            localStorage.removeItem('token');
            localStorage.removeItem('selectedAddress');
            localStorage.setItem('temp_user', JSON.stringify(tmpUserObj));
        }
    },
})

export const {setCredentials, logOut} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;