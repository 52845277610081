import { Container }
from "react-bootstrap";
import { library }
from "@fortawesome/fontawesome-svg-core";
import { fas }
from "@fortawesome/free-solid-svg-icons";
import { fab }
from "@fortawesome/free-brands-svg-icons";
import { far }
from "@fortawesome/free-regular-svg-icons";

import "../static/css/App.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Banners from "../components/home/banners";
import Deals from "../components/home/deals";
import OfferBanners from "../components/home/offerbanners";
import CateProducts from "../components/home/cateproducts";
import Brands from "../components/home/brands";
import SingleBanner from "../components/home/singlebanner";
import OfferOne from "../components/home/offersone";
import AppContent from "../components/home/appcontent";
import RecentPurchase from "../components/home/recentPurchase";
import HomeProductList from "../components/home/homeProductList";
import BrandList from "../components/home/BrandList";
import BannerProducts from "../components/home/BannerProducts";
import { useDispatch, useSelector }
from "react-redux";
import { useEffect, useState }
from "react";
import {
fetchSections,
        changeStateTrue,
        changeStateFalse,
        changeLoading,
}
from "../features/homeSlice";
library.add(fas, fab, far);

function Home() {
    const dispatch = useDispatch();
    const {sectionList, productList, error, response} = useSelector(
            (state) => state.home
    );
    const [list, setList] = useState([]);
    const [banners, setBanners] = useState([]);
//        useEffect(() => {
//        dispatch(changeLoading());
//                dispatch(fetchSections());
//        }, [dispatch]);
    useEffect(() => {
        console.log(sectionList);
        console.log("Loaded");
        if (sectionList.length > 0) {
            setList(sectionList);
        }
    }, [sectionList]);
    useEffect(() => {
        console.log("loading");
        setList([]);
        dispatch(changeLoading());
        dispatch(fetchSections());
    }, []);
    return (
            <Container fluid="xl" className="px-md-5 px-3 px-xl-3">
                {list &&
                            list.length > 0 &&
                            list.map(
                                    (sec) =>
                                (sec.homerow &&
                                        sec.secType >= 1 &&
                                        sec.secType <= 4 &&
                                        sec.homerow.map(
                                                (hrow) =>
                                            hrow.homedata.length > 0 &&
                                                    ((sec.secType === 1 && <Banners data={hrow.homedata} />) ||
                                                            (sec.secType === 2 && (
                                                                    <HomeProductList
                                                                        productList={productList}
                                                                        title={hrow.title}
                                                                        data={hrow.homedata}
                                                                        key={hrow.id}
                                                                        />
                                                                        )) ||
                                                                (sec.secType === 3 && (
                                                                    <OfferBanners key={hrow.id} title={sec.title} data={hrow.homedata} />
                                                                    )) ||
                                                            (sec.secType === 4 && (
                                                                    <BrandList  key={hrow.id} brandList={hrow.homedata} />
                                                                    )))
                                        )) ||
                                        (sec.secType === 5 && sec.secType === 5 && (
                                                <BannerProducts key={sec.id} {...sec.homerow} {...sec}  productList={productList} />
                                                ))
                            )}
            
                <RecentPurchase />
            
                <AppContent />
            </Container>
            );
}

export default Home;
{/* <Brands />
     <SingleBanner />
     <OfferOne section={0} title="Offers On daily needs" />
     <OfferOne section={1} title="Offers on Oil, Masala & More..." /> */
}

{/* <Deals /> */
}
{/* <OfferBanners /> */
}
{/* <CateProducts /> */
}