import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const commonState = {
    //    updateState: false,
    //    loading: false,
    branchList: [],
    offset: 0,
    error: "",
    response: "",
    completed: false,
};

export const fetchBranchList = createAsyncThunk(
        "category/fetchBranchList",
        async (data, {getState}) => {
    const response = await axios.post(process.env.REACT_APP_BASE_URL + `/branch/all`);
    return response.data;
}
);

const commonSlice = createSlice({
    name: "common",
    initialState: commonState,
    reducers: {
        changeStateTrue: (state) => {
            state.completed = false;
        },
        changeStateFalse: (state) => {
            state.updateState = false;
        },
        clearResponse: (state) => {
            state.branchList = [];
            state.offset = 0;
            state.error = "";
            state.response = "";
            state.completed = false;
        },
    },
    extraReducers: (builder) => {
        //        builder
        //                .addCase(addEmployee.pending, (state) => {
        //                    state.loading = true;
        //                })
        //                .addCase(addEmployee.fulfilled, (state, action) => {
        //                    state.loading = false;
        //                    state.employeeList.push(action.payload);
        //                    state.response = "add";
        //                })
        //                .addCase(addEmployee.rejected, (state, action) => {
        //                    state.loading = false;
        //                    state.error = action.error.message;
        //                });

        builder
                .addCase(fetchBranchList.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        state.branchList = action.payload.data;
                    }
                })
                .addCase(fetchBranchList.rejected, (state, action) => {
                    state.error = action.error.message;
                });
    },
});

export default commonSlice.reducer;
export const {changeStateTrue, changeStateFalse, clearResponse} =
        commonSlice.actions;


