import { fetchOrderDetails, clearState }
from "../../features/profileSlice";
import { useDispatch, useSelector }
from "react-redux";
import { useState, useEffect }
from "react";
import {  useParams }
from 'react-router-dom';
import { Container, Row, Col, Image, Breadcrumb, Table, Card, Badge, ListGroup, Tab, Tabs, Nav, Button, Toast, ToastContainer, Alert}
from 'react-bootstrap';
import { library }
from '@fortawesome/fontawesome-svg-core';
import { fas }
from '@fortawesome/free-solid-svg-icons';
import { fab }
from '@fortawesome/free-brands-svg-icons';
import { far }
from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }
from '@fortawesome/react-fontawesome';
import '../../static/css/App.css';
import moment from 'moment';
library.add(fas, fab, far);

function OrderDetails() {

    const dispatch = useDispatch();
    const {id} = useParams();
    const {orderDetails, orderStatus} = useSelector(
            (state) => state.profile
    );
    useEffect(() => {
        dispatch(clearState());
        dispatch(fetchOrderDetails(id));
    }, [dispatch]);
    return (
            <div>
                {orderDetails && <Container fluid className="px-5">
                    <Row className="my-5">
                        <h3>Order Status: <span className="text-warning">{orderStatus ? orderStatus[orderDetails.status] : ""}           
                            </span>
                        </h3>           
                    </Row>
                    <Row className="bg-light py-5">
                        <Col sm={3}>
                        <h5>Order Details</h5>
                        <p className="mb-1">Your Order Id:<span className="text-danger">            
                                {orderDetails.showId}        
                            </span></p>
                        <p className="mb-1">Placed On:
                            {moment(orderDetails.postedDate, "YYYY-MM-DD").format("DD/MM/YYYY")}            
                        </p>
                        </Col>                       
                        <Col sm={3}>
                        <h5>Address</h5>
                        <p className="mb-1">
                            <span className="text-danger">
                                {orderDetails.shippingAddress.name}
                            </span>
                        </p>
                        <p className="mb-1">
                            {orderDetails.shippingAddress.address}            
                        </p>
                        <p className="mb-1">
                            {orderDetails.shippingAddress.landmark}            
                        </p>
                        <p className="mb-1">            
                            {orderDetails.shippingAddress.area},{orderDetails.shippingAddress.city}-{orderDetails.shippingAddress.pincode}
                        </p>
                        <p className="mb-1">
                            {orderDetails.shippingAddress.state}            
                        </p>
                        </Col>                       
                        <Col sm={3}>
                        <h5>Payment Method</h5>
                        <p className="mb-1"><span className="text-danger">
                                {orderDetails.paymentType == 1 ? "Cash On Delivery" : ""}            
                            </span>
                        </p>            
                        </Col>                       
                        <Col sm={3}>
                        <h5>Need Help?</h5>
                        <p className="mb-1">We happy to assist you</p>            
                        <p className="mb-1"><span className="text-danger">+91 9876543210</span></p>          
                        </Col>                       
                    </Row>
                    <Row className="mt-4"> 
                        <Col sm="8">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="col-1">#</th>
                                    <th className="col-5">Products</th>
                                    <th className="col-2 text-center">Quantity</th>
                                    <th className="col-2 text-end">Net Price</th>
                                    <th className="col-2 text-end">Total Price</th>
                                </tr>
                            </thead>
                            <tbody>                              
                                {orderDetails && orderDetails.products.map((cartProduct, index) => (
                                        <tr key={index}>
                                            {cartProduct.isFreeProduct == 0 && <td className="align-middle">{index + 1}</td>}
                                            {cartProduct.isFreeProduct == 1 && <td className="align-middle"></td>}
                                            <td>
                                    <Row className="align-items-center">
                                        <Col xs={6} md={3}>
                                        <Image src={`https://gracesupermarket.s3.ap-southeast-2.amazonaws.com/uploads/${cartProduct.productImage}`} rounded style={{height: "45px"}}/>
                                        </Col>
                                        <Col xs={6} md={9}>
                                        <span className="mb-0" style={{fontSize: "14px"}}>{cartProduct.productName}</span><br/>
                                        <Badge bg="success" className="">
                                            {cartProduct.offerType == 3 && cartProduct.discountType == 0 && <div>{cartProduct.discountValue}%</div>}
                                            {cartProduct.offerType == 3 && cartProduct.discountType == 1 && <div><FontAwesomeIcon icon="fa-solid fa-indian-rupee-sign" size="xs" />{cartProduct.discountValue}</div>}
                                            {cartProduct.offerType == 4 && cartProduct.isFreeProduct == 0 && <div>BOGO</div>}
                                            {cartProduct.offerType == 4 && cartProduct.isFreeProduct == 1 && <div>BOGO Free Product</div>}
                                            {cartProduct.offerType == 5 && cartProduct.isFreeProduct == 0 && <div>Free Offer</div>}
                                            {cartProduct.offerType == 5 && cartProduct.isFreeProduct == 1 && <div>Free Product</div>} 
                                        </Badge>
                                        </Col>
                                    </Row>
                                    </td>
                                    <td>
                                        <div class="input-group mb-3">                       
                                            <input type="text" class="form-control p-1 text-center" value={cartProduct.productQty} disabled aria-label=""/>                       
                                        </div>
                                    </td>
                                    <td className="text-end">
                                        <span className="w-100 float-start fw-bold color-primary" style={{fontSize: "13px"}}><FontAwesomeIcon icon="fa-solid fa-indian-rupee-sign" size="sm" />&nbsp;{cartProduct.salePrice}</span>
                                    </td>
                                    <td className="text-end">
                                        <span className="w-100 float-start fw-bold color-primary" style={{fontSize: "13px"}}><FontAwesomeIcon icon="fa-solid fa-indian-rupee-sign" size="sm" />&nbsp;{(cartProduct.proTotalPrice).toFixed(2)}</span>
                                    </td>
                                    </tr>
                                                        ))}            
                            </tbody>
                        </Table>
                        </Col>
                        <Col sm={4}>
                        <Card bg="light">
                        <Card.Body className="p-0 pt-3">
                            <Card.Title className="text-center">Order Summary</Card.Title>
                            <ListGroup variant="" className="text-start">
                                <ListGroup.Item>Sub Total <span className="float-end">{orderDetails.subTotal}            
                                    </span></ListGroup.Item>
                                <ListGroup.Item>Total Discount <span className="float-end">                                                 
                                        {orderDetails.oDiscountAmount}
                                    </span></ListGroup.Item>
                                <ListGroup.Item>Delivery Charges <span className="float-end">Free</span></ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                        <Card.Footer>
                            <small className=""><strong>Total Amount</strong><span className="float-end">            
                                    {orderDetails.totalAmount}            
                                </span></small>
                        </Card.Footer>
                        </Card>
                        </Col>
                    </Row>
                </Container>}
            </div>
            );
}




export default OrderDetails;

