import {
  fetchProductDetails,
  changeLoading,
  addMonthlyList,
  addProductReview,
} from "../features/productSlice";
import Login from "../common/login";
import Register from "../common/register";
import {
  addCart,
  fetchCart,
  changeStateTrue,
  changeStateFalse,
  addToCart,
  removeFromCart,
} from "../features/cartSlice";
import {
  selectCurrentUser,
  selectCurrentToken,
  logOut,
} from "../features/auth/authSlice";
import ProductCard from "../components/product/product";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  ToggleButton,
  ToggleButtonGroup,
  ProgressBar,
  ToastContainer,
  Toast,
  Badge,
  Modal,
} from "react-bootstrap";
import Rating from "react-rating";
import OwlCarousel from "react-owl-carousel";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../static/css/App.css";
library.add(fas, fab, far);
function ProductDetails() {
  const parameters = useParams();
  const dispatch = useDispatch();
  const [imgSrc, setImgSrc] = useState(null);
  const [imgThumb, setImgThumbnail] = useState(null);
  const [productDet, setProductDet] = useState(null);
  const [variationId, setVariationId] = useState(0);
  const [ratings, setRatings] = useState({
    five: 0,
    four: 0,
    three: 0,
    two: 0,
    one: 0,
  });
  const [show, setShow] = useState(false);
  const [addCarts, setAddCart] = useState({ itemId: "", qty: "" });
  const [cartExists, setCartExists] = useState(false);
  const [cartQty, setCartQty] = useState("");
  const [discountStatus, setDiscountStatus] = useState(false);
  const [discountValue, setDiscountValue] = useState({ type: 0, value: 0 });
  const [salesPrice, setSalesPrice] = useState(0);
  const [freeProduct, setFreeProduct] = useState(null);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [addRating, setAddRating] = useState(0);
  const [addReviewComment, setAddReviewComment] = useState(null);
  const [productReview, setProductReview] = useState([]);
  const { cartListNew } = useSelector((state) => state.cart);
  const { singleProductDetails, error, response, monthlyListSuccess } =
    useSelector((state) => state.product);
  const changeImage = (e) => {
    setImgSrc(e.target.src);
  };
  const handleOfferSection = (variation) => {
    setDiscountStatus(false);
    if (variation.offer_type === 3) {
      setDiscountStatus(true);
      if (variation.value_type === 0) {
        setSalesPrice(
          variation.mrpPrice -
            (variation.offer_Value / 100) * variation.mrpPrice
        );
        setDiscountValue({ type: 0, value: variation.offer_Value });
      }
      if (variation.value_type === 1) {
        setSalesPrice(variation.mrpPrice - variation.offer_Value);
        setDiscountValue({ type: 1, value: variation.offer_Value });
      }
    } else if (variation.offer_type === 4) {
      setDiscountStatus(true);
      setSalesPrice(variation.mrpPrice);
      setDiscountValue({ type: 2, value: 0 });
    } else if (variation.offer_type === 5) {
      setDiscountStatus(true);
      setSalesPrice(variation.mrpPrice);
      setDiscountValue({ type: 3, value: 0 });
      var opi = variation.offer_products_id;
      var offerProductId = singleProductDetails.freeProducts.filter(function (
        el
      ) {
        return el.offer_products_id == opi;
      });
      setFreeProduct({
        name:
          offerProductId[0].ProductName +
          " " +
          offerProductId[0].unit +
          offerProductId[0].unitName,
        slug: offerProductId[0].productSlug,
        qty: offerProductId[0].freeQty,
      });
    }
  };

  const handleVariation = (val) => {
    setVariationId(val);
    var variantDet = singleProductDetails.product.variations.filter(function (
      el
    ) {
      return el.productItemId == val;
    });
    setProductDet(variantDet[0]);
    setImgThumbnail(variantDet[0].image);
    setSalesPrice(variantDet[0].salePrice);
    setImgSrc("https://gracesupermarket.s3.ap-southeast-2.amazonaws.com/uploads/" + variantDet[0].image[0]);
    handleOfferSection(variantDet[0]);
  };
  const handleAddCart = (item, qty, amount) => {
    if (token) {
      console.log(amount);
      dispatch(
        addToCart({
          productItemId: item,
          quantity: 1,
          totalQuantity: 1,
          salesPrice: amount,
        })
      );
    } else {
      setLoginModalShow(true);
    }
  };
  const handleIncrement = (item, qty, amount) => {
    dispatch(
      addToCart({
        productItemId: item,
        quantity: 1,
        totalQuantity: 1,
        price: amount,
        status: true,
      })
    );
  };
  const handleDecrement = (item, qty, amount) => {
    //        setAddCart({itemId:item, qty:qty - 1});
    dispatch(removeFromCart({ productItemId: item, price: amount }));
  };
  const handleMonthlyList = (e) => {
    let datas = {
      productId: singleProductDetails.product.productId,
      productItemId: variationId,
      branchId: "2",
      quantity: 1,
    };
    dispatch(addMonthlyList(datas));
  };
  const addReview = () => {
    setShowReviewModal(true);
    setAddRating(0);
  };
  const handleReviewModalClose = () => {
    setShowReviewModal(false);
  };
  const storeReview = () => {
    setShowReviewModal(false);
    dispatch(
      addProductReview({
        productId: singleProductDetails.product.productId,
        rating: addRating,
        message: addReviewComment,
      })
    );
  };
  useEffect(() => {
    dispatch(changeLoading());
    setProductDet(null);
    setImgThumbnail(null);
    setImgSrc(null);
    dispatch(fetchProductDetails(parameters.slug));
  }, [dispatch]);
  useEffect(() => {
    dispatch(changeLoading());
    setProductDet(null);
    setImgThumbnail(null);
    setImgSrc(null);
    dispatch(fetchProductDetails(parameters.slug));
  }, [parameters]);
  useEffect(() => {
    if (monthlyListSuccess) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [monthlyListSuccess]);
  useEffect(() => {
    setDiscountStatus(false);
    setRatings({ five: 0, four: 0, three: 0, two: 0, one: 0 });
    if (singleProductDetails != "") {
      //single product details
      setImgThumbnail(singleProductDetails.product.variations[0].image);
      setImgSrc(
        "https://gracesupermarket.s3.ap-southeast-2.amazonaws.com/uploads/" +
          singleProductDetails.product.variations[0].image[0]
      );
      setProductDet(singleProductDetails.product.variations[0]);
      setVariationId(singleProductDetails.product.variations[0].productItemId);
      //cart exists check
      if (cartListNew.length > 0) {
        var exis = cartListNew.filter(
          (el) =>
            el.productItemId ===
            singleProductDetails.product.variations[0].productItemId
        );
        if (exis.length > 0) {
          setCartExists(true);
          setCartQty(exis[0].quantity);
        } else {
          setCartExists(false);
          setCartQty("");
        }
      } else {
        setCartExists(false);
        setCartQty("");
      }
      setSalesPrice(singleProductDetails.product.variations[0].salePrice);
      handleOfferSection(singleProductDetails.product.variations[0]);
      //Ratings Percentage
      if (singleProductDetails.ratingCount.length > 0) {
        let five = singleProductDetails.ratingCount[0].five_s;
        let four = singleProductDetails.ratingCount[0].four_s;
        let three = singleProductDetails.ratingCount[0].three_s;
        let two = singleProductDetails.ratingCount[0].two_s;
        let one = singleProductDetails.ratingCount[0].one_s;
        let totalRating =
          parseInt(five) +
          parseInt(four) +
          parseInt(three) +
          parseInt(two) +
          parseInt(one);
        let five_per = (
          parseFloat(parseInt(five) / parseInt(totalRating)) * 100
        ).toFixed(2);
        let four_per = (
          parseFloat(parseInt(four) / parseInt(totalRating)) * 100
        ).toFixed(2);
        let three_per = (
          parseFloat(parseInt(three) / parseInt(totalRating)) * 100
        ).toFixed(2);
        let two_per = (
          parseFloat(parseInt(two) / parseInt(totalRating)) * 100
        ).toFixed(2);
        let one_per = (
          parseFloat(parseInt(one) / parseInt(totalRating)) * 100
        ).toFixed(2);

        setRatings({
          five: five_per,
          four: four_per,
          three: three_per,
          two: two_per,
          one: one_per,
        });
      }
      setProductReview(singleProductDetails.productReviews);
      if (singleProductDetails.userproductReviews.length > 0) {
        let reviewId = singleProductDetails.userproductReviews[0].reviewId;
        setProductReview(
          [...singleProductDetails.productReviews].sort((a) =>
            a.reviewId == reviewId ? -1 : 1
          )
        );
      }
    }
  }, [singleProductDetails]);
  useEffect(() => {
    if (productDet) {
      //cart exists check
      if (cartListNew.length > 0) {
        var exis = cartListNew.filter(
          (el) => el.productItemId === productDet.productItemId
        );
        if (exis.length > 0) {
          setCartExists(true);
          setCartQty(exis[0].quantity);
        } else {
          setCartExists(false);
          setCartQty("");
        }
      } else {
        setCartExists(false);
        setCartQty("");
      }
    }
  }, [cartListNew, productDet]);
  const token = useSelector(selectCurrentToken);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [registerModalShow, setRegisterModalShow] = useState(false);
  const [mobileNo, setMobileNo] = useState("");
  const [otpScreen, setOtpScreen] = useState(false);
  return (
    <div>
      <Container fluid="xl"  className="px-md-5 px-3 px-xl-3">
        <Row>
          <Col sm={6}>
            <Row className="border-end">
              <Col sm={2}>
                {imgThumb &&
                  imgThumb.map((img, idx) => (
                    <Image
                      key={idx}
                      onMouseEnter={changeImage}
                      src={`https://gracesupermarket.s3.ap-southeast-2.amazonaws.com/uploads/${img}`}
                      thumbnail
                      className="mt-2"
                    />
                  ))}
              </Col>
              <Col
                sm={10}
                className="d-flex align-items-center justify-content-center position-relative"
                style={{ height: "300px" }}
              >
                <Image src={imgSrc} rounded className="mw-100 mh-100" />
                {discountStatus && (
                  <Badge
                    bg="success"
                    className="position-absolute top-0 end-0 rounded-circle offer-badge"
                  >
                    {discountValue.type == 0 && (
                      <div>{discountValue.value}%</div>
                    )}
                    {discountValue.type == 1 && (
                      <div>
                        <FontAwesomeIcon
                          icon="fa-solid fa-indian-rupee-sign"
                          size="xs"
                        />
                        {discountValue.value}
                      </div>
                    )}
                    {discountValue.type == 2 && <div>BOGO</div>}
                    {discountValue.type == 3 && <div>Free</div>}
                  </Badge>
                )}
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <h3>
              {singleProductDetails
                ? singleProductDetails.product.productName
                : ""}
            </h3>
            <h5 className="text-secondary">
              {singleProductDetails
                ? singleProductDetails.product.brandName
                : ""}
            </h5>

            <Col sm={12} className="d-flex">
              <Rating
                start="0"
                stop="5"
                readonly="true"
                emptySymbol={<FontAwesomeIcon icon="fa-regular fa-star" />}
                fullSymbol={<FontAwesomeIcon icon="fa-solid fa-star" />}
                initialRating={
                  singleProductDetails
                    ? singleProductDetails.productRatings[0].average_rating
                    : 0
                }
              />
              <p className="ms-4 ps-4 border-start">
                {singleProductDetails &&
                singleProductDetails.productRatings[0].average_rating
                  ? "(" +
                    singleProductDetails.productRatings[0].average_rating +
                    ")"
                  : "(0)"}{" "}
                {singleProductDetails
                  ? singleProductDetails.productRatings[0].total_rates_count
                  : 0}{" "}
                Ratings
              </p>
              <p className="ms-4 ps-4 border-start">
                {singleProductDetails
                  ? singleProductDetails.productReviews.length
                  : 0}{" "}
                Reviews
              </p>
              <p
                className="ms-4 ps-4 border-start cursor-pointer"
                onClick={handleMonthlyList}
              >
                {!monthlyListSuccess &&
                  productDet &&
                  !productDet.wishlistId && (
                    <FontAwesomeIcon icon="fa-regular fa-heart" />
                  )}
                {monthlyListSuccess ||
                  (productDet && productDet.wishlistId && (
                    <FontAwesomeIcon icon="fa-solid fa-heart" />
                  ))}
                Add to Monthly List
              </p>
            </Col>
            <Col sm={12} className="mb-2">
              {productDet && productDet.stock > 0 && (
                <span className="text-success">
                  <b>In Stock</b>
                </span>
              )}
              {productDet && productDet.stock <= 0 && (
                <span className="text-danger">
                  <b>Out Of Stock</b>
                </span>
              )}
            </Col>
            <Col sm={12} className="mb-2">
              {discountStatus && discountValue.type == 2 && (
                <div>
                  <p className="">
                    <FontAwesomeIcon icon="fa-solid fa-tags" size="sm" /> Buy
                    One Get One Offer{" "}
                  </p>
                </div>
              )}
              {discountStatus && discountValue.type == 3 && (
                <div>
                  <p className="">
                    <FontAwesomeIcon icon="fa-solid fa-tags" size="sm" /> Buy
                    One Get {freeProduct.qty}{" "}
                    <strong>{freeProduct.name}</strong> Free{" "}
                  </p>
                </div>
              )}
            </Col>
            <Col sm={12} className="price-row h3 d-block">
              <p>
                <FontAwesomeIcon
                  icon="fa-solid fa-indian-rupee-sign"
                  size="xs"
                />
                {productDet ? parseFloat(salesPrice).toFixed(2) : ""}{" "}
                {productDet &&
                  parseFloat(productDet.mrpPrice).toFixed(2) !=
                    parseFloat(salesPrice).toFixed(2) && (
                    <span className="text-secondary h6">
                      MRP:
                      <FontAwesomeIcon icon="fa-solid fa-indian-rupee-sign" />
                      <s>
                        {productDet
                          ? parseFloat(productDet.mrpPrice).toFixed(2)
                          : ""}
                      </s>
                    </span>
                  )}
              </p>
            </Col>
            <Col sm={12}>
              <ToggleButtonGroup
                type="radio"
                name="variations"
                className="variantClass"
                value={variationId}
                onChange={handleVariation}
              >
                {singleProductDetails &&
                  singleProductDetails.product.variations.map((vari, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`tbg-btn-${idx}`}
                      value={vari.productItemId}
                      variant="outline-success"
                      className={`me-2 ${
                        vari.productItemId == variationId ? "active" : ""
                      }`}
                    >
                      {vari.unit} {vari.unitName}
                    </ToggleButton>
                  ))}
              </ToggleButtonGroup>
            </Col>
            <Col sm={12} className="mt-4">
              {productDet && productDet.stock > 0 && !cartExists && (
                <Button
                  variant="danger"
                  className="btn-sm"
                  onClick={() =>
                    handleAddCart(productDet.productItemId, 1, salesPrice)
                  }
                >
                  + Add to cart
                </Button>
              )}
              {productDet && productDet.stock > 0 && cartExists && (
                <div className="input-group mb-3 w-25">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text cursor-pointer"
                      onClick={() =>
                        handleDecrement(
                          productDet.productItemId,
                          productDet.quantity,
                          salesPrice
                        )
                      }
                    >
                      -
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control p-1 text-center"
                    value={cartQty}
                    disabled
                    aria-label="Amount (to the nearest dollar)"
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text cursor-pointer"
                      onClick={() =>
                        handleIncrement(
                          productDet.productItemId,
                          productDet.quantity,
                          salesPrice
                        )
                      }
                    >
                      +
                    </span>
                  </div>
                </div>
              )}
            </Col>
          </Col>
        </Row>
        <Row>
          <h4>Ratings & Review</h4>
          <Col sm={12} className="d-flex">
            <Col sm={4} className="border-end text-center">
              <p>
                <b>
                  {singleProductDetails &&
                  singleProductDetails.productRatings[0].average_rating
                    ? singleProductDetails.productRatings[0].average_rating
                    : 0}
                  /5
                </b>
              </p>
              <Rating
                start="0"
                stop="5"
                readonly="true"
                emptySymbol={<FontAwesomeIcon icon="fa-regular fa-star" />}
                fullSymbol={<FontAwesomeIcon icon="fa-solid fa-star" />}
                initialRating={
                  singleProductDetails
                    ? singleProductDetails.productRatings[0].average_rating
                    : 0
                }
              />
              <p>
                <b>Based on Ratings</b>
              </p>
              <Col sm={12}>
                <Col
                  sm={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  5<FontAwesomeIcon icon="fa-solid fa-star" />
                  &nbsp;&nbsp;&nbsp;
                  <ProgressBar
                    now={ratings.five}
                    style={{ height: "6px", width: "50%" }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  {singleProductDetails
                    ? singleProductDetails.ratingCount[0].five_s
                    : 0}
                </Col>
                <Col
                  sm={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  4<FontAwesomeIcon icon="fa-solid fa-star" />
                  &nbsp;&nbsp;&nbsp;
                  <ProgressBar
                    now={ratings.four}
                    style={{ height: "6px", width: "50%" }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  {singleProductDetails
                    ? singleProductDetails.ratingCount[0].four_s
                    : 0}
                </Col>
                <Col
                  sm={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  3<FontAwesomeIcon icon="fa-solid fa-star" />
                  &nbsp;&nbsp;&nbsp;
                  <ProgressBar
                    now={ratings.three}
                    style={{ height: "6px", width: "50%" }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  {singleProductDetails
                    ? singleProductDetails.ratingCount[0].three_s
                    : 0}
                </Col>
                <Col
                  sm={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  2<FontAwesomeIcon icon="fa-solid fa-star" />
                  &nbsp;&nbsp;&nbsp;
                  <ProgressBar
                    now={ratings.two}
                    style={{ height: "6px", width: "50%" }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  {singleProductDetails
                    ? singleProductDetails.ratingCount[0].two_s
                    : 0}
                </Col>
                <Col
                  sm={12}
                  className="d-flex justify-content-center align-items-center"
                >
                  1<FontAwesomeIcon icon="fa-solid fa-star" />
                  &nbsp;&nbsp;&nbsp;
                  <ProgressBar
                    now={ratings.one}
                    style={{ height: "6px", width: "50%" }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  {singleProductDetails
                    ? singleProductDetails.ratingCount[0].one_s
                    : 0}
                </Col>
              </Col>
            </Col>
            <Col sm={8} className="px-3">
              <Row>
                {singleProductDetails &&
                  singleProductDetails.userproductReviews.length == 0 && (
                    <Button className="col-3 ms-auto" onClick={addReview}>
                      Write Review
                    </Button>
                  )}
              </Row>
              <Row>
                {productReview.length > 0 &&
                  productReview.map((reviews, index) => (
                    <Col
                      sm={12}
                      className="border-bottom pb-2 mb-2"
                      key={index}
                    >
                      <span className="badge badge-pill bg-success mb-3">
                        {reviews.rating}{" "}
                        <FontAwesomeIcon icon="fa-solid fa-star" />
                      </span>
                      <p className="text-break">{reviews.message}</p>
                      <p>
                        <b>{reviews.memberName}</b>
                      </p>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Col>
        </Row>
        <Row className="mt-5">
          <h4>Related Products</h4>
          <Col>
            <OwlCarousel
              className="owl-theme px-0"
              autoplay
              margin={10}
              items={5}
              dots={false}
            >
              {singleProductDetails &&
                singleProductDetails.relatedProducts.map((product, index) =>
                  product.variations.length > 0 && index <= 10 ? (
                    <ProductCard
                      product={product}
                      index={index}
                      type="owl"
                      key={index}
                    />
                  ) : (
                    ""
                  )
                )}
            </OwlCarousel>
          </Col>
        </Row>

        <ToastContainer
          className="p-3"
          position="bottom-center"
          style={{ zIndex: 1 }}
        >
          <Toast
            bg="success"
            onClose={() => setShow(false)}
            show={show}
            delay={3000}
            autohide
          >
            <Toast.Body className="text-white text-center">
              <b>Monthly List Added successfully</b>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </Container>

      <Modal show={showReviewModal} onHide={handleReviewModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Rating</Form.Label>&nbsp;&nbsp;
              <Rating
                start={0}
                stop={5}
                step={1}
                fractions={4}
                initialRating={addRating}
                emptySymbol={<FontAwesomeIcon icon="fa-regular fa-star" />}
                fullSymbol={<FontAwesomeIcon icon="fa-solid fa-star" />}
                onChange={(e) => setAddRating(e)}
              />{" "}
              &nbsp;&nbsp;&nbsp;
              {addRating}
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Review</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => setAddReviewComment(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleReviewModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={storeReview}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Login
        show={loginModalShow}
        tab={"login-mobile"}
        otpScreen={otpScreen}
        mobileNo={mobileNo}
        onHide={() => {
          setLoginModalShow(false);
          setOtpScreen(false);
        }}
        onRegister={() => {
          setRegisterModalShow(true);
          setLoginModalShow(false);
          setOtpScreen(false);
        }}
      />
      <Register
        show={registerModalShow}
        onHide={() => setRegisterModalShow(false)}
        mobileNo={setMobileNo}
        otpShow={() => {
          setRegisterModalShow(false);
          setLoginModalShow(true);
          setOtpScreen(true);
        }}
      />
    </div>
  );
}

export default ProductDetails;
