import AboutUs from "../components/static/about-us";
import DeliveryInfo from "../components/static/delivery-info";
import PrivacyPolicy from "../components/static/privacy-policy";
import Terms from "../components/static/terms-conditions";
import FAQ from "../components/static/faq";
import Payments from "../components/static/payment-details";
import Cancellation from "../components/static/cancellation";

import { useState, useEffect }
from "react";
import {  useParams, useLocation }
from 'react-router-dom';

import {Container, Row, Col, Tab, Nav }
from 'react-bootstrap';
import { library }
from '@fortawesome/fontawesome-svg-core';
import { fas }
from '@fortawesome/free-solid-svg-icons';
import { fab }
from '@fortawesome/free-brands-svg-icons';
import { far }
from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }
from '@fortawesome/react-fontawesome';
import '../static/css/App.css';

library.add(fas, fab, far);
function Static() {
    let location = useLocation();

    return (
            <div>
                <Container fluid="xl"  className="px-md-5 px-3 px-xl-3">                                   
                    <Row>
                        {location && location.pathname == "/about-us" && <AboutUs />}
                        {location && location.pathname == "/delivery-info" && <DeliveryInfo />}
                        {location && location.pathname == "/privacy" && <PrivacyPolicy />}
                        {location && location.pathname == "/terms-conditions" && <Terms />}
                        {location && location.pathname == "/faq" && <FAQ />}
                        {location && location.pathname == "/payments" && <Payments />}
                        {location && location.pathname == "/cancellation-returns" && <Cancellation />}                        
                    </Row>
                </Container>
            </div>
            );
}

export default Static;

