/* 
 * Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
 * Click nbfs://nbhost/SystemFileSystem/Templates/ClientSide/javascript.js to edit this template
 */


import { useState, useEffect }
from "react";
import {  useParams, useLocation }
from 'react-router-dom';

import {Container, Row, Col, Tab, Nav }
from 'react-bootstrap';
import { library }
from '@fortawesome/fontawesome-svg-core';
import { fas }
from '@fortawesome/free-solid-svg-icons';
import { fab }
from '@fortawesome/free-brands-svg-icons';
import { far }
from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }
from '@fortawesome/react-fontawesome';

library.add(fas, fab, far);
function DeliveryInfo() {

    return (
            <div>
                <Row><h2>Delivery Information</h2></Row>
                <br/>
                <Row>
                    <p>
                        <b>What are the delivery charges?</b><br/>
                        Delivery charge varies with each Location. While orders of Rs 1000 or above are delivered free. Charges may vary for Express delivery and Standard Delivery.<br/><br/>
            
                        <b>What types of deliveries available?</b><br/>
                        We provide two types of deliveries. Standard delivery and Express delivery. Each delivery availability will be depends on customers locations. Take away also available for customers who wishing to pick the order in store itself.<br/><br/>
            
                        <b>What is standard delivery?</b><br/>
                        You have probably noticed varying estimated delivery times for each locations to the products you are interested in. Delivery times are influenced by product availability, your shipping destination and the courier partner's time-to-deliver in your location. Please enter your default pin code (you don't have to enter it every single time) to know more accurate delivery times.<br/><br/>
            
                        <b>What is Express Delivery?</b><br/>
                        3 hour delivery is our promise to deliver your order in a conveniently space three hour delivery slot.<br/><br/>
            
                        For instance - If you place an order at 12:55 PM, you can select any of the delivery slots following the time of order e.g. 1 PM - 4 PM. Your order will be delivered between the time specified in the delivery slot. You will get an email/SMS once the order is dispatched from the warehouse.<br/><br/>
            
                        10AM - 1PM ( Fresh Slot )<br/>
                        1PM - 4PM (Afternoon Slot)<br/>
                        4PM - 9PM (Meant for Working Peoples)<br/><br/>
                        <b>What is Take Away?</b><br/>
                        Problem with delivery slots? No worries! We have solution for your thoughts. You can place your order online and receive your products through take away. You can place order to your nearest branch through online and also with mobile app.<br/><br/>
            
                        <b>Why is the COD option not offered in my location?</b><br/>
                        Availability of COD depends on the ability of our courier partner servicing your location to accept cash as payment at the time of delivery.<br/><br/>
            
                        Our courier partners have limits on the cash amount payable on delivery depending on the destination and your order value might have exceeded this limit. Please enter your pin code on the product page to check if COD is available in your location.<br/><br/>
            
                        <b>Does Grace Super Market deliver out of Chennai?</b><br/>
                        As of now, Grace Super Market doesn't deliver items out of Chennai. In case your order is large and you are willing to pay for certain amount of delivery charge for some specific products with larger shelf life, call us and we will get back to you.<br/><br/></p>
                </Row>
            </div>

            );
}

export default DeliveryInfo;