import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const brandState = {
    //    updateState: false,
    //    loading: false,
    brandList: [],
    offset: 0,
    error: "",
    response: "",
    completed: false,
};

export const fetchBrand = createAsyncThunk(
        "category/fetchBrand",
        async (data, {getState}) => {
    const brand = getState().brand;
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));    
    const response = await axios.get(process.env.REACT_APP_PRODUCT_BASE_URL + `/brands/get?limit=20&offset=${brand.offset}&branchId=${branch.id}`);
    return response.data;
}
);

const brandSlice = createSlice({
    name: "brand",
    initialState: brandState,
    reducers: {
        changeStateTrue: (state) => {
            state.completed = false;
            state.offset = ((state.offset) + 20);
        },
        changeStateFalse: (state) => {
            state.updateState = false;
        },
        clearResponse: (state) => {
            state.brandList = [];
            state.offset = 0;
            state.error = "";
            state.response = "";
            state.completed = false;
        },
    },
    extraReducers: (builder) => {
        //        builder
        //                .addCase(addEmployee.pending, (state) => {
        //                    state.loading = true;
        //                })
        //                .addCase(addEmployee.fulfilled, (state, action) => {
        //                    state.loading = false;
        //                    state.employeeList.push(action.payload);
        //                    state.response = "add";
        //                })
        //                .addCase(addEmployee.rejected, (state, action) => {
        //                    state.loading = false;
        //                    state.error = action.error.message;
        //                });

        builder
                .addCase(fetchBrand.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        state.brandList = [...state.brandList, ...action.payload.data];
                        state.completed = true;
                    }
                })
                .addCase(fetchBrand.rejected, (state, action) => {
                    state.error = action.error.message;
                });
    },
});

export default brandSlice.reducer;
export const {changeStateTrue, changeStateFalse, clearResponse} =
        brandSlice.actions;


