/* 
 * Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
 * Click nbfs://nbhost/SystemFileSystem/Templates/ClientSide/javascript.js to edit this template
 */


import { useState, useEffect }
from "react";
import {  useParams, useLocation }
from 'react-router-dom';

import {Container, Row, Col, Tab, Nav, Accordion  }
from 'react-bootstrap';
import { library }
from '@fortawesome/fontawesome-svg-core';
import { fas }
from '@fortawesome/free-solid-svg-icons';
import { fab }
from '@fortawesome/free-brands-svg-icons';
import { far }
from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }
from '@fortawesome/react-fontawesome';

library.add(fas, fab, far);
function FAQ() {

    return (
            <div>
                <Row><h2>Frequently Asked Questions</h2></Row>
                <br/>
                <Row>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header><b>What does Grace do?</b></Accordion.Header>
                            <Accordion.Body>
                                Grace is a complete ecommerce solution that allows you to set up an online store to buy the products.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header><b>What methods of payment are allowed?</b></Accordion.Header>
                            <Accordion.Body>
                                Mostly we accept credit card payments but we can offer more options like Cash on Demand, Money Orders, etc.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header><b>What cities and locations do you operate in?</b></Accordion.Header>
                            <Accordion.Body>
                                Grace currently operates from its branches located in VADAPALANI, K.K.NAGAR, ASHOK PILLAR KAMARAJAR SALAI, VALASARAVAKKAM KODAMBAKKAM, MMDA COLONY, PORUR ALWARTHIRUNAGAR, RAMAPURAM, KILPAUK, VILLIVAKKAM, ALAPAKKAM, MTH ROAD, MUGALIVAKKAM
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header><b>Do you deliver to my location?</b></Accordion.Header>
                            <Accordion.Body>
                                We deliver in select localities across the cities we are present in. You can edit your location settings to check if we deliver in your area.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header><b>Can I have multiple accounts with same mobile number and email id?</b></Accordion.Header>
                            <Accordion.Body>
                                Each email address and phone number can be associated with one grace super market account only. Yet Customer info and offers given only for eCommerce it cannot be obtained in Mortar store
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Row>
            </div>

            );
}

export default FAQ;