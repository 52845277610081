import { useDispatch, useSelector }
from "react-redux";
import { useState, useEffect }
from "react";
import { useNavigate } from "react-router-dom";
import GoogleMapModal from "../../common/liveGmap";
import {fetchDeliverySlot, fetchDeliveryAddress, fetchTakeawaySlot, checkCartStatus, clearStock }
from "../../features/cartSlice";
import { clearState, deleteShippingAddress } from "../../features/profileSlice";
import axios from 'axios';
import { Container, Row, Col, Image, Breadcrumb, Table, Card, ListGroup, Tab, Tabs, Nav, Button, Form, Modal,Alert }
from 'react-bootstrap';
import { library }
from '@fortawesome/fontawesome-svg-core';
import { fas }
from '@fortawesome/free-solid-svg-icons';
import { fab }
from '@fortawesome/free-brands-svg-icons';
import { far }
from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }
from '@fortawesome/react-fontawesome';
import '../../static/css/App.css';
//import { useGetCartQuery, useAddCartMutation } from "../features/productApi";
import moment from 'moment';
library.add(fas, fab, far);

function ShippingAddress(props) {
    const [key, setKey] = useState('home');
    const [address, setAddress] = useState(null);
    const [takeAwayBranch, setTakeAwayBranch] = useState(JSON.parse(localStorage.getItem('selectedBranch')));
    const dispatch = useDispatch();
    const {deliverySlot, deliveryAddress, timeSlot, error, response, outOfStock} = useSelector(
            (state) => state.cart
    );
    const {updateState, success} = useSelector(
            (state) => state.profile
    );
    const [timeSlots, setTimeSlots] = useState();

    const [addresss, setAddresss] = useState(null);
    const [addressModalShow, setAddressModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [outStockShow, setOutStockShow] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        let getData = setTimeout(() => {
            dispatch(fetchDeliverySlot());
            dispatch(fetchDeliveryAddress());
        }, 0);
        return () => clearTimeout(getData);
    }, [dispatch]);

    const handleDateChange = (e) => {
        setTimeSlots(deliverySlot[e.target.value]);
        props.setdeliveryDetails({...props.deliveryDetails, deliveryDate: e.target.value});
    }
    const handleTimeChange = (e) => {
        props.setdeliveryDetails({...props.deliveryDetails, slotId: parseInt(e.target.value)});
    }

    const handleAddressEdit = (addr) => {
        setIsEdit(true);
        setAddresss(addr);
        setAddressModalShow(true);
    };

    const handleAddressDelete = (addrId) => {
        setDeleteModalShow(true);
        setDeleteId(addrId);
    };
    const confirmDelete = () => {
        setDeleteModalShow(false);
        dispatch(clearState());
        dispatch(deleteShippingAddress(deleteId));
    };

    const redirectCart = () => {
        setOutStockShow(false);
        window.location.href = "/cart";
    }


    // Get Branch API by Lat&Lng
    const fetchBranchesByLatLng = async (latitude, longitude) => {
        try {
            let brchUrl = process.env.REACT_APP_BASE_URL+`/branches/getBylatLong`;
            const response = await axios.post(brchUrl, {
                latitude: latitude,
                longitude: longitude,
            });
            const branch = response.data.data[0];
            if (branch) {
//                localStorage.setItem('availableBranches', JSON.stringify(response.data.data));
                localStorage.setItem('selectedBranch', JSON.stringify(branch));
                return branch;
            } else {
//                localStorage.removeItem('availableBranches');
//                localStorage.removeItem('selectedBranch');
                return null;
            }


        } catch (error) {
            console.error('Error fetching branches:', error);
            return null;
        }
    };

    useEffect(() => {
        if (success) {
            dispatch(fetchDeliveryAddress());
        }
    }, [success]);


    useEffect(() => {
        setTimeSlots(timeSlot);
    }, [timeSlot]);

    useEffect(() => {
        if (deliveryAddress.length > 0) {
            const addressId = JSON.parse(localStorage.getItem("selectedAddress"));
            setAddress(addressId ? addressId.address : deliveryAddress[0].id);
        }

    }, [deliveryAddress]);
    useEffect(() => {
        props.setdeliveryDetails({...props.deliveryDetails, deliveryDate: Object.keys(deliverySlot)[0]});
    }, [deliverySlot]);
    useEffect(() => {
        props.setdeliveryDetails({...props.deliveryDetails, shippingId: 0});
        const addressId = JSON.parse(localStorage.getItem("selectedAddress"));
        if (addressId && address !== addressId.address && deliveryAddress.length > 0) {
            let addr = deliveryAddress.find(x => x.id === address);
            let branch;
            (async () => {
                branch = await fetchBranchesByLatLng(addr.latitude, addr.longitude);
                if (!branch) {
                    alert("Cant delivery to this location");
                } else {
                    localStorage.setItem('selectedAddress', JSON.stringify({address: addr.id}));
                    localStorage.setItem('selectedLocation', JSON.stringify({area: addr.area, pincode: addr.pincode, lat: addr.latitude, lng: addr.longitude}));
                    dispatch(clearStock());
                    dispatch(checkCartStatus());
                }
            })();
        } else {
            props.setdeliveryDetails({...props.deliveryDetails, shippingId: address});
        }
    }, [address]);

    useEffect(() => {
        if (outOfStock) {
            props.setdeliveryDetails({...props.deliveryDetails, shippingId: 0});
            setOutStockShow(true);
        } else {
            props.setdeliveryDetails({...props.deliveryDetails, shippingId: address});
        }
    }, [outOfStock])
    useEffect(() => {
        if (timeSlots && timeSlots.length > 0) {
            props.setdeliveryDetails({...props.deliveryDetails, slotId: timeSlots[0].id});
        }
    }, [timeSlots]);
    useEffect(() => {
        if (key == "takeAway") {
            props.setdeliveryDetails({...props.deliveryDetails, deliveryType: 2});
            dispatch(fetchTakeawaySlot());
        } else {
            props.setdeliveryDetails({...props.deliveryDetails, deliveryType: 1});
            dispatch(fetchDeliverySlot());
        }
    }, [key]);

    return (
            <div>
                <Tabs
                    activeKey={key}
                    id="shipping-address-tabs"
                    className="mb-3"
                    onSelect={(k) => setKey(k)}
                    justify
                    >                    
                    <Tab eventKey="home" title="Home or Office Delivery">
                        <Row className='justify-content-center'>
                            <Col md='6'>
                            <Card className='bg-body-tertiary checkout-sa-card'>
                            <Card.Body>
                                <Card.Title>Select Delivery Date</Card.Title> 
                                <Form.Select aria-label="Delivery date" className='mt-3' onChange={handleDateChange}>       
                                    {deliverySlot && Object.entries(deliverySlot).map(([key, val]) => (<option value={key}>{moment(key, "MM/DD/YYYY").format("DD/MM/YYYY")}</option>))}
                                </Form.Select>
                            </Card.Body>
                            </Card>
                            </Col>
                            <Col md='6'>
                            <Card className='bg-body-tertiary checkout-sa-card'>
                            <Card.Body>
                                <Card.Title>Select Delivery Time</Card.Title> 
                                <Form.Select aria-label="Delivery Time" className='mt-3' onChange={handleTimeChange}>                                
                                    {timeSlots && timeSlots.map((slot) => (<option value={slot.id}>{moment(slot.fromTime, "HH:mm:ss").format("ha")} to {moment(slot.toTime, "HH:mm:ss").format("ha")}</option>))}
                                </Form.Select>
                            </Card.Body>
                            </Card>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <h5>Select Your Delivery Address</h5>
                            <div className='col-12'>
                            <Row><Button className="col-6 col-sm-3 ms-auto mb-3" onClick={() => handleAddressEdit("")}><FontAwesomeIcon icon="fa-solid fa-plus" /> Add Address</Button></Row>
                                <Row className="">
                                    {deliveryAddress && deliveryAddress.map((addresses,key) => (
                                <Card key={`delivery-address-${key}`} className={"txtCard bg-body-tertiary col-5 m-3 cursor-pointer " + (address == addresses.id ? 'active-address' : '')}>
                                <Card.Body onClick={(k) => setAddress(addresses.id)}>
                                    <Form.Check type="radio" id={"check-api-radio-" + addresses.id}>
                                        <Form.Check.Input className=" cursor-pointer "  type="radio" checked={(address === addresses.id ? 'checked' : '')}/>
                                        <Form.Check.Label className=" cursor-pointer ">
                                            <span className="address-name">{addresses.name}</span><br/>
                                            {addresses.address}<br/>
                                            {addresses.area}<br/>
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Card.Body>
                                <Card.Footer className="text-end">                                                                       
                                    <Card.Link className="text-decoration-none color-primary cursor-pointer" onClick={() => handleAddressEdit(addresses)}><FontAwesomeIcon icon="fa-solid fa-edit" /> Edit</Card.Link>
                                    <Card.Link className="text-decoration-none color-primary cursor-pointer" onClick={() => handleAddressDelete(addresses.id)}><FontAwesomeIcon icon="fa-solid fa-trash-can" /> Delete</Card.Link>
                                </Card.Footer> 
                                </Card>

                                                                                        ))}
                                                                                {deliveryAddress.length==0 && <Alert className="text-center">Please add shipping address</Alert>}
                                                                             
                            </Row>
                        </div>
                    </Row>
                </Tab>
                <Tab eventKey="takeAway" title="Take Away">
                    <Row className='justify-content-center'>
                        <Col md='12' className="my-5">
                        <Card className='bg-body-tertiary checkout-sa-card col-6 m-auto text-center pt-4'>
                        <Card.Title>Pick Up Branch</Card.Title> 
                        <Card.Body>                           
                            {takeAwayBranch.name} - {takeAwayBranch.pincode}
                        </Card.Body>
                        </Card>
                        </Col>
                        <Col md='6' className="mb-5">
                        <Card className='bg-body-tertiary checkout-sa-card'>
                        <Card.Body>
                            <Card.Title>Select Delivery Date</Card.Title> 
                            <Form.Select aria-label="Delivery date" className='mt-3' onChange={handleDateChange}>       
                                {deliverySlot && Object.entries(deliverySlot).map(([key, val]) => (<option value={key}>{moment(key, "MM/DD/YYYY").format("DD/MM/YYYY")}</option>))}
                            </Form.Select>
                        </Card.Body>
                        </Card>
                        </Col>
                        <Col md='6'>
                        <Card className='bg-body-tertiary checkout-sa-card'>
                        <Card.Body>
                            <Card.Title>Select Delivery Time</Card.Title> 
                            <Form.Select aria-label="Delivery Time" className='mt-3'>                                
                                {timeSlots && timeSlots.map((slot) => (<option value={slot.id}>{moment(slot.fromTime, "HH:mm:ss").format("ha")} to {moment(slot.toTime, "HH:mm:ss").format("ha")}</option>))}
                            </Form.Select>
                        </Card.Body>
                        </Card>
                        </Col>
                    </Row>
                </Tab> 
        
            </Tabs>
            <GoogleMapModal
                showModal={addressModalShow}
                handleCloseModal={() => {
                                                                setAddressModalShow(false);
                                                                setIsEdit(false);
                                                                dispatch(fetchDeliveryAddress());
                                                            }}
                edit={isEdit}
                editAddress={addresss}                 
                />
        
            <Modal
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                className="text-center"
                >
                <Modal.Header className="mx-auto">
                    <Modal.Title id="contained-modal-title-vcenter text-danger">
                        Alert
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Are you Sure</h4>
                    <p>
                        You want to delete?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => confirmDelete()}>Delete</Button>
                    <Button className="bg-secondary border-secondary" onClick={() => setDeleteModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        
            <Modal
                show={outStockShow}                
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="text-center"
                >
                <Modal.Header className="mx-auto">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Alert
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>                    
                    <p>
                        Some Products went out of stock.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={redirectCart}>Ok</Button>
                </Modal.Footer>
            </Modal>
        </div>
                                )

                    }

                    export default ShippingAddress;