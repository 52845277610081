import { changePassword } from "../../features/profileSlice";
import { logOut } from "../../features/auth/authSlice";
import { useDispatch, useSelector }
from "react-redux";
import { useState, useEffect }
from "react";
import { Container, Form, Button, Row,Alert}
from 'react-bootstrap';
import { library }
from '@fortawesome/fontawesome-svg-core';
import { fas }
from '@fortawesome/free-solid-svg-icons';
import { fab }
from '@fortawesome/free-brands-svg-icons';
import { far }
from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }
from '@fortawesome/react-fontawesome';
import '../../static/css/App.css';
library.add(fas, fab, far);


function ChangePassword() {

    const dispatch = useDispatch();
    const [updatePassword, setUpdatePassword] = useState({oldPassword: "", newPassword: "", confirmPassword: ""});
    const {updateState, error} = useSelector(
            (state) => state.profile
    );
    const handleChangePassword = () => {
        if (updatePassword.newPassword == "" || (updatePassword.newPassword.length < 8 || updatePassword.newPassword !== updatePassword.confirmPassword)) {
            alert("Password Should not be empty , Should be 8 characters, Confirm password should match new password");
        } else {
            dispatch(changePassword(updatePassword));
        }
    }

    const handleInputValue = (e) => {
        setUpdatePassword({
            ...updatePassword,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        if (updateState) {
            dispatch(logOut());
        }
    }, [updateState])


    return (
            <div>
                <Container fluid className="">
                    {error && <Alert variant="danger" dismissible className="text-center">                        
                        <p className="mb-0">
                            {error}
                        </p>
                    </Alert>} 
                    <Form className="col-6 m-auto">
                        <Form.Group className="mb-3" controlId="formOldPassword">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control placeholder="Current Password" type="password" name="oldPassword" onChange={handleInputValue}/>                            
                        </Form.Group>            
                        <Form.Group className="mb-3" controlId="formNewPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control placeholder="New Password" type="password"  name="newPassword" onChange={handleInputValue}/>
                        </Form.Group>  
                        <Form.Group className="mb-3" controlId="formConfirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control placeholder="Confirm Password" type="password"  name="confirmPassword" onChange={handleInputValue}                                          />                            
                        </Form.Group>                         
            
                        <Button variant="primary" className="d-block m-auto" onClick={handleChangePassword}>
                            Update Profile
                        </Button>
            
                    </Form>
                </Container>
            </div>
            );
}




export default ChangePassword;

