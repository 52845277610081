import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const homeState = {
  productList: [],
  offset: 0,
  limit: 100,
  error: "",
  response: "",
  completed: false,
  sectionList: [],
};

export const fetchSections = createAsyncThunk(
  "homepage",
  async (data, { getState }) => {
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL +
        `/homepage?limit=100&offset=0&branchId=${branch.id}`
    );
    return response.data;
  }
);

const homeSlice = createSlice({
  name: "homepage",
  initialState: homeState,
  reducers: {
    changeStateTrue: (state) => {
      state.completed = false;
      state.offset = state.offset + 20;
    },
    changeStateFalse: (state) => {
      state.updateState = false;
    },
    clearResponse: (state) => {
      state.response = "";
    },
    changeLoading: (state) => {
      state.sectionList = [];
      state.productList = [];
      state.offset = 0;
      state.completed = false;
    },

    clearHome: (state) => {
      state.productList = [];
      state.sectionList = [];
      state.offset = 0;
      state.completed = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSections.fulfilled, (state, action) => {
        //                    console.log(action);
        if (action.payload.status == "SUCCESS") {
          state.sectionList = [
            ...state.productList,
            ...action.payload.data.mainData,
          ];
          state.productList = [
            ...state.productList,
            ...action.payload.data.products,
          ];
          state.completed = true;
        }
      })
      .addCase(fetchSections.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export default homeSlice.reducer;
export const {
  changeStateTrue,
  changeStateFalse,
  clearResponse,
  changeLoading,
  clearHome,
} = homeSlice.actions;
