import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {selectCurrentUser, selectCurrentToken} from "./auth/authSlice";
import axios from "axios";

const profileState = {
    updateState: false,
    //    loading: false,
    error: "",
    response: "",
    myProfile: [],
    myOrders: [],
    orderDetails: null,
    orderStatus: null,
    shippingAddress: [],
    success: false
};

export const fetchMyProfile = createAsyncThunk(
        "customer/myprofile",
        async (data, {getState}) => {
    const token = getState().auth.token;
    const response = await axios.get(process.env.REACT_APP_CUSTOMER_BASE_URL + `/customer/myprofile`, {headers: {Authorization: token}});
    //  console.log(response.data);
    return response.data;
}
);
export const updateMyProfile = createAsyncThunk(
        "customer/updatemyprofile",
        async (data, {getState}) => {
    const token = getState().auth.token;
    const response = await axios.post(process.env.REACT_APP_CUSTOMER_BASE_URL + `/customer/myprofile/update`, data, {headers: {Authorization: token}});
    //  console.log(response.data);
    return response.data;
}
);
export const mobileVerify = createAsyncThunk(
        "customer/verifymobile",
        async (data, {getState}) => {
    const token = getState().auth.token;
    const response = await axios.post(process.env.REACT_APP_CUSTOMER_BASE_URL + `/customer/mobile/verify`, data, {headers: {Authorization: token}});
    //  console.log(response.data);
    return response.data;
}
);

export const fetchMyOrders = createAsyncThunk(
        "orders/get",
        async (data, {getState}) => {
    const token = getState().auth.token;
    const response = await axios.get(process.env.REACT_APP_ORDER_BASE_URL + `/orders/get`, {headers: {Authorization: token}});
    //  console.log(response.data);
    return response.data;
}
);

export const fetchOrderDetails = createAsyncThunk(
        "orders/details",
        async (data, {getState}) => {
    const token = getState().auth.token;
    const response = await axios.get(process.env.REACT_APP_ORDER_BASE_URL + `/orders/details/` + data, {headers: {Authorization: token}});
    //  console.log(response.data);
    return response.data;
}
);

export const changePassword = createAsyncThunk(
        "customer/changePassword",
        async (data, {getState}) => {
    const token = getState().auth.token;
    const response = await axios.post(process.env.REACT_APP_CUSTOMER_BASE_URL + `/customer/password/change`, data, {headers: {Authorization: token}});
    //  console.log(response.data);
    return response.data;
}
);

export const fetchShippingAddress = createAsyncThunk(
        "customer/fetchShippingAddress",
        async (data, {getState}) => {
    const token = getState().auth.token;
    const response = await axios.get(process.env.REACT_APP_CUSTOMER_BASE_URL + `/customer/getAddress`, {headers: {Authorization: token}});
    //  console.log(response.data);
    return response.data;
}
);

export const deleteShippingAddress = createAsyncThunk(
        "customer/deleteShippingAddress",
        async (data, {getState}) => {
    const token = getState().auth.token;
    const response = await axios.post(process.env.REACT_APP_CUSTOMER_BASE_URL + `/customer/deleteAddress/${data}`, {}, {headers: {Authorization: token}});
    //  console.log(response.data);
    return response.data;
}
);




const ProfileSlice = createSlice({
    name: "profile",
    initialState: profileState,
    reducers: {
        clearState: (state) => {
            state.error = "";
            state.updateState = false;
            state.response = "";
            state.myProfile = [];
            state.myOrders = [];
            state.shippingAddress = [];
            state.orderDetails = null;
            state.orderStatus = null;
            state.success = false;
        }
    },
    extraReducers: (builder) => {

        builder
                .addCase(fetchMyProfile.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        state.myProfile = action.payload.data.result;
                    }
                })
                .addCase(fetchMyProfile.rejected, (state, action) => {
                    state.error = action.error.message;
                });
        builder
                .addCase(fetchMyOrders.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        console.log(action.payload.data);
                        state.myOrders = action.payload.data;
                        state.orderStatus = action.payload.OrderStatusData;
                    }
                })
                .addCase(fetchMyOrders.rejected, (state, action) => {
                    state.error = action.error.message;
                });
        builder
                .addCase(fetchOrderDetails.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        state.orderDetails = action.payload.data;
                        state.orderStatus = action.payload.OrderStatusData;
                    }
                })
                .addCase(fetchOrderDetails.rejected, (state, action) => {
                    state.error = action.error.message;
                });
        builder
                .addCase(updateMyProfile.fulfilled, (state, action) => {
                    state.updateState = true;
                    if (action.payload.status == "SUCCESS") {
                        state.error = "";
//                        state.myOrders = action.payload.data;
//                        state.orderStatus = action.payload.OrderStatusData;
                    } else {
                        state.error = action.payload.message;
                    }
                })
                .addCase(updateMyProfile.rejected, (state, action) => {
                    state.error = action.error.message;
                });
        builder
                .addCase(mobileVerify.fulfilled, (state, action) => {
                    state.updateState = true;
                    if (action.payload.status == "SUCCESS") {
                        state.error = "";
                        state.updateState = false;
//                        state.myOrders = action.payload.data;
//                        state.orderStatus = action.payload.OrderStatusData;
                    } else {
                        state.error = action.payload.message;
                    }
                })
                .addCase(mobileVerify.rejected, (state, action) => {
                    state.error = action.error.message;
                });
        builder
                .addCase(changePassword.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
//                        console.log(state.myProfile.name);
                        state.updateState = true;
//                        state.orderStatus = action.payload.OrderStatusData;
                    } else {
                        state.error = action.payload.message;
                    }
                })
                .addCase(changePassword.rejected, (state, action) => {
                    state.error = action.error.message;
                });
        builder
                .addCase(fetchShippingAddress.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
//                        console.log(state.myProfile.name);
                        state.shippingAddress = action.payload.data?.result;
//                        state.orderStatus = action.payload.OrderStatusData;
                    } else {
                        state.error = action.payload.message;
                    }
                })
                .addCase(fetchShippingAddress.rejected, (state, action) => {
                    state.error = action.error.message;
                });
        builder
                .addCase(deleteShippingAddress.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        state.success = true;
//                        state.shippingAddress = action.payload.data?.result;
//                        state.orderStatus = action.payload.OrderStatusData;
                    } else {
                        state.error = action.payload.message;
                    }
                })
                .addCase(deleteShippingAddress.rejected, (state, action) => {
                    state.error = action.error.message;
                });
    }
});

export default ProfileSlice.reducer;
export const {clearState} =
        ProfileSlice.actions;


