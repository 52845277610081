import { NavLink } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";

import OwlCarousel from "react-owl-carousel";
import offsecbanner1 from "../../static/images/homepage/offsec/offsection-1.png";
import offsecbanner2 from "../../static/images/homepage/offsec/offsection-2.png";
import offsecbanner3 from "../../static/images/homepage/offsec/offsection-3.png";
import offsecbanner4 from "../../static/images/homepage/offsec/offsection2-1.png";
import offsecbanner5 from "../../static/images/homepage/offsec/offsection2-2.png";
import offsecbanner6 from "../../static/images/homepage/offsec/offsection2-3.png";
function OfferOne({ section, title }) {
  let offerbannerArray = [
    [offsecbanner1, offsecbanner2, offsecbanner3],
    [offsecbanner4, offsecbanner5, offsecbanner6],
  ];
  let offerbannerLink = [
    ["/ct/laundry", "/ct/fruits", "/ct/baby-nappies"],
    ["/bd/grace", "/ct/refined-sunflower-oil", "/ct/flours"],
  ];
  // console.log('consolellooo',offerbannerArray[section]);
  return (
    <Row>
      <h3 className="px-0 py-3 ofTitle">{title}</h3>

      {Array.from({ length: 3 }).map((_, idx) => (
        <Col key={idx}>
          <Card className="border-0">
            <NavLink to={offerbannerLink[section][idx]} className="mw-100">
              <Card.Img variant="top" src={offerbannerArray[section][idx]} />
            </NavLink>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
export default OfferOne;
