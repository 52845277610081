/* 
 * Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
 * Click nbfs://nbhost/SystemFileSystem/Templates/ClientSide/javascript.js to edit this template
 */


import { useState, useEffect }
from "react";
import {  useParams, useLocation }
from 'react-router-dom';

import {Container, Row, Col, Tab, Nav }
from 'react-bootstrap';
import { library }
from '@fortawesome/fontawesome-svg-core';
import { fas }
from '@fortawesome/free-solid-svg-icons';
import { fab }
from '@fortawesome/free-brands-svg-icons';
import { far }
from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }
from '@fortawesome/react-fontawesome';

library.add(fas, fab, far);
function AboutUs() {

    return (
            <Tab.Container id="left-tabs-example" defaultActiveKey="about">
                <Row>
                    <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="about">About us</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="vision">Our Vision & Purpose</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="quality">Quality Standards</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </Col>
                    <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="about">   
                            <b>GRACE SUPERMARKET’s</b> first retail store was commissioned in Chennai<br/>
                            in the year 1972 in a humble way by Mr. S. Raja Kumar’s father and founder<br/>
                            Mr. M. Selvaraj.<br/>
                            Subsequently the reign was taken over by Mr. S. Raja Kumar- the current<br/>
                            managing partner of M/s. Grace Supermarket.<br/>
                            Under his leadership, the augmentation took superior elevation and has<br/>
                            positioned in the modern supermarket style of functioning into Organization.<br/><br/>
            
                            Its top most business strategy is to give customers, wide range of commodities,<br/>
                            value for money and best in class service. This genuine thought proved spectacularly<br/>
                            successful over the years.<br/>
                            With 25 outlets in Chennai, as of mid-2020, GSM has products across household<br/>
                            grocery essentials spanning to 25000 + products displayed in areas ranging<br/>
                            from 1500 to 10,000 sq.ft, that are managed by a passionate and dedicated<br/>
                            team.<br/><br/>
            
                            Banking on continued support from customers, and to keep pace with consumer<br/>
                            expectation, Grace Super Market has ventured into on-line channel in the year<br/>
                            2018, again in a humble manner. With overflowing encouragement, we have<br/>
                            deployed dedicated team to cater to customer need in ensuring range<br/>
                            availability, pricing and delivery mechanism.<br/><br/>
            
                            And…..we will continue to strive to do the best……of course with Customer<br/>
                            support !  <br/><br/>
            
                        </Tab.Pane>
                        <Tab.Pane eventKey="vision">    
                            <b>Our Vision</b><br/>
                            <b>To be India’s dominant and pioneering omni-channel, gourmet retailer.</b><br/><br/>
            
                            Redefining India’s freshest and finest food experience<br/><br/>
            
                            To expand our footprint across metro & tier-I cities with our stores and to all India with our E/M-commerce service<br/><br/>
            
                            To provide a brighter customer experience with an assortment of finest local and international foods and highest level of service<br/><br/>
            
                            To continue to set benchmarks in gourmet food retailing<br/><br/>
            
                            <b>Purpose</b><br/>
                            To spread the joy of food<br/><br/>
            
                        </Tab.Pane>
                        <Tab.Pane eventKey="quality">  
                            <b>Grace Super Market Quality Policy</b><br/>
                            Quality is a word synonymous with Grace and we at Grace Super Market take pride in our stringent quality standards. We go to great lengths and take the utmost care and precaution to ensure that each day our customers get nothing but the very best across all our products, services & interactions. Here are a few quality standards that we practice at Grace Super Market.<br/><br/>
            
                            <b>Only A+ Quality Levels</b><br/>
                            When you walk into a Grace Super Market store you can be sure that you will get the best quality level of fresh produce and bakery products in the city. Our teams take great pains to locate and source only the finest available produce in every fruit and vegetable. Our bakery products too are developed especially as per our stringiest standards.<br/><br/>
            
                            <b>Everyday Freshness</b><br/>
                            All fresh produce and bakery products retailed at our stores are procured on a daily basis. These are delivered every morning to the stores. Stale and old produce of the previous days is discarded or sent back to be resold through other channels. This ensures that Grace Super Market consumers always get to choose from a wide variety of the freshest produce & bakery products.<br/><br/>
            
                            <b>Appropriate Care In Storage</b><br/>
                            We store our products as per recommended conditions and ideal temperatures. We do not switch off our Freezers, Chillers or other refrigeration equipment at night. This is to ensure that temperature sensitive products are stored at correct temperatures all through. We switch on exhaust fans at night to adequate ensure air circulation for ambient products. Even though our store running costs are higher, we believe that this is a small price to pay to ensure 100% fit for consumption products at all times.<br/><br/>
            
                            <b>Only High Quality Vendors</b><br/>
                            We only deal with suppliers and vendors who trade ethically. We do not deal with the grey market or with vendors who have a reputation to deal in products by abusing and altering sell by dates. In the unlikely event that any of our suppliers is caught tampering with sell by dates, we penalize them appropriately and their supply contracts to our stores are terminated.<br/><br/>
            
                            <b>Exhaustive Stock Checks</b><br/>
                            We thoroughly check all our branded products for expiry dates each month and segregate those products that are close to expiry. These products are either sent back to vendors or discarded completely. Products that are sent back to vendors are usually re-sold through other channels by independent retail stores.<br/><br/>
            
                            <b>Expert Care (Management)</b><br/>
                            All our store representatives compulsorily wear hand gloves and hairnet while handling food. The store and all fixtures are sanitized at regular intervals. Food storage and display areas are cleaned with food grade cleaners as per prefixed schedules.<br/><br/>
            
                            <b>Return Policy</b><br/>
                            In the unlikely event that the products purchased from us are expired or spoilt when purchased, we would be happy to replace or exchange the foods so purchased or refund the money to the customer.<br/><br/>
            
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            );
}

export default AboutUs;