import {
  fetchMontlyList,
  changeLoading,
  addMonthlyList,
  deleteMontlyList,
  addMontlyListCart,
} from "../../features/productSlice";
import { fetchCart } from "../../features/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Table,
  Col,
  Image,
  Modal,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../static/css/App.css";
library.add(fas, fab, far);

function MonthlyList() {
  const dispatch = useDispatch();
  const {
    monthlyListProduct,
    error,
    response,
    monthlyListSuccess,
    deleteSuccess,
    monthlyListCartSuccess,
  } = useSelector((state) => state.product);

  const [listId, setListId] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [cartId, setCartId] = useState([]);

  const handleMonthlyDelete = (id) => {
    if (id) {
      setCartId([id]);
    }
    console.log(cartId);
    setModalShow(true);
  };
  const handleDelete = (e) => {
    dispatch(deleteMontlyList({ wishlistId: cartId.join(",") }));
    setModalShow(false);
  };
  const addAllCart = () => {
    setCartId(null);
    dispatch(addMontlyListCart(cartId));
  };
  const addCart = () => {
    dispatch(addMontlyListCart(cartId));
  };
  const handleClose = () => {
    setModalShow(false);
    setListId([]);
    setCartId([]);
  };

  const handleSelectId = (id) => {
    if (cartId.length > 0) {
      var exists = cartId.some((el) => el === id);
      if (exists) {
        setCartId(cartId.filter((el) => el != id));
      } else {
        setCartId([...cartId, id]);
      }
    } else {
      setCartId([id]);
    }
  };

  useEffect(() => {
    dispatch(changeLoading());
    dispatch(fetchMontlyList());
    setCartId([]);
  }, [dispatch]);

  useEffect(() => {
    console.log(monthlyListProduct);
  }, [monthlyListProduct]);
  useEffect(() => {
    dispatch(fetchCart());
  }, [monthlyListCartSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(changeLoading());
      dispatch(fetchMontlyList());
      setCartId([]);
    }
  }, [deleteSuccess]);

  return (
    <div>
      <Container fluid className="">
        <Row>
          <Col sm={8}>
            <Button className="bg-dark  border-dark me-5" onClick={addAllCart}>
              {" "}
              Add all to Cart
            </Button>
            <Button className="" onClick={addCart}>
              Add selected items to cart
            </Button>
          </Col>
          <Col sm={4} className="text-end">
            <Button
              className="bg-secondary  border-secondary"
              onClick={(e) => handleMonthlyDelete(null)}
            >
              Delete
            </Button>
          </Col>
        </Row>
        <Table bordered hover className="mt-3">
          <tbody>
            {monthlyListProduct.length > 0 &&
              monthlyListProduct.map((ml, index) => (
                <tr key={index}>
                  <td className="col-1 text-center align-middle">
                    {ml.stock > 0 && (
                      <Form.Check
                        type="checkbox"
                        id={`ml-${index}`}
                        aria-label={`ml-${index}`}
                        onChange={() => handleSelectId(ml.wishlistId)}
                      />
                    )}
                  </td>
                  <td className="col-6">
                    <Row className="border-end">
                      <Col sm={2}>
                        <NavLink to={`/pd/${ml.slug}`}>
                          <Image
                            src={`https://gracesupermarket.s3.ap-southeast-2.amazonaws.com/uploads/${ml.image[0]}`}
                            thumbnail
                            className="mt-2"
                          />
                        </NavLink>
                      </Col>
                      <Col sm={10}>
                        <p className="td-none">
                          <NavLink to={`/pd/${ml.slug}`}>
                            <b>{ml.productName}</b>
                          </NavLink>
                        </p>
                        <p className="text-secondary">{ml.brandName}</p>
                        <p className="">
                          <b>
                            {ml.unit} {ml.unitName}
                          </b>
                        </p>
                      </Col>
                    </Row>
                  </td>
                  <td className="col-2 align-middle">
                    <div className="input-group mb-3 w-75 m-auto">
                      <input
                        type="number"
                        min="1"
                        className="form-control p-1 text-center"
                        value={ml.quantity}
                        aria-label="Amount (to the nearest dollar)"
                      />
                    </div>
                  </td>
                  <td className="col-2">
                    <p className="text-secondary">
                      MRP:
                      <FontAwesomeIcon
                        icon="fa-solid fa-indian-rupee-sign"
                        size="xs"
                      />
                      <s>{ml.mrpPrice.toFixed(2)} </s>&nbsp;&nbsp;
                      <span className="text-dark">
                        <b>
                          <FontAwesomeIcon
                            icon="fa-solid fa-indian-rupee-sign"
                            size="xs"
                          />
                          {ml.salePrice.toFixed(2)}
                        </b>
                      </span>
                    </p>
                    <p className="text-secondary">
                      {ml.quantity}x
                      <FontAwesomeIcon
                        icon="fa-solid fa-indian-rupee-sign"
                        size="xs"
                      />
                      {ml.salePrice.toFixed(2)}&nbsp;&nbsp;
                      <span className="text-dark">
                        <b>
                          <FontAwesomeIcon
                            icon="fa-solid fa-indian-rupee-sign"
                            size="xs"
                          />
                          {(ml.quantity * ml.salePrice).toFixed(2)}
                        </b>
                      </span>
                    </p>
                  </td>
                  <td className="col-1 text-center align-middle">
                    <p
                      className="text-danger cursor-pointer"
                      onClick={() => handleMonthlyDelete(ml.wishlistId)}
                    >
                      <FontAwesomeIcon icon="fa-solid fa-trash-can" />
                    </p>
                  </td>
                </tr>
              ))}

            {monthlyListProduct.length <= 0 && (
              <tr className="col-1 text-center align-middle">
                <td className="text-danger">No list as of now.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        show={modalShow}
      >
        <Modal.Header>
          <Modal.Title
            className="text-danger m-auto h3"
            id="contained-modal-title-vcenter"
          >
            <b>Warning</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="h4 text-center">
            Are you sure?
            <br />
            You want to delete?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="m-auto" onClick={(e) => handleDelete(e)}>
            Yes Delete!
          </Button>
          <Button
            className="m-auto bg-secondary border-secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MonthlyList;
