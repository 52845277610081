import CartDetails from "../components/checkout/cart";
import ShippingAddress from "../components/checkout/ShippingAddress";
import PaymentDetails from "../components/checkout/PaymentDetails";
import { placeOrder } from "../features/cartSlice";
import Login from "../common/login";
import Register from "../common/register";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  selectCurrentUser,
  selectCurrentToken,
  logOut,
} from "../features/auth/authSlice";
import {
  Container,
  Row,
  Col,
  Image,
  Breadcrumb,
  Table,
  Card,
  ListGroup,
  Tab,
  Tabs,
  Nav,
  Button,
  Toast,
  ToastContainer,
  Alert,
} from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../static/css/App.css";
import { useGetCartQuery, useAddCartMutation } from "../features/productApi";
library.add(fas, fab, far);
function Cart() {
  const dispatch = useDispatch();
  const [cartAmount, setCartAmount] = useState(0);
  const [cartCounts, setCartCount] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [showCart, setShowCart] = useState(true);
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState("");
  const [showAddress, setShowAddress] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  //    const [addCart] = useAddCartMutation();
  const [tabName, setTabName] = useState("cart");
  const token = useSelector(selectCurrentToken);
  const { placeOrderStatus, orderId, cartCount } = useSelector(
    (state) => state.cart
  );
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [registerModalShow, setRegisterModalShow] = useState(false);
  const [mobileNo, setMobileNo] = useState("");
  const [otpScreen, setOtpScreen] = useState(false);
  const branch = JSON.parse(localStorage.getItem("selectedBranch"));
  const [deliveryDetails, setDeliveryDetails] = useState({
    branchId: "9",
    deliveryType: 0,
    deliveryDate: null,
    slotId: 0,
    shippingId: 0,
    paymentType: null,
  });
  const handleTabChange = (tab) => {
    if (token) {
      if (tab === "cart") {
        setShowCart(false);
        setShowAddress(true);
        setShowPayment(false);
        setTabName("shipping");
      } else if (tab === "shipping") {
        if (!deliveryDetails.deliveryDate) {
          setToast(true);
          setToastError("Select Delivery Date");
        } else if (
          deliveryDetails.deliveryType == 1 &&
          (!deliveryDetails.shippingId || deliveryDetails.shippingId == 0)
        ) {
          setToast(true);
          setToastError("Select Delivery address");
        } else if (deliveryDetails.slotId == 0) {
          setToast(true);
          setToastError("Select Delivery time");
        } else {
          setShowCart(false);
          setShowAddress(false);
          setShowPayment(true);
          setTabName("payment");
        }
      } else {
        if (!deliveryDetails.paymentType) {
          setToast(true);
          setToastError("Select Payment type");
        } else {
          dispatch(placeOrder(deliveryDetails));
        }
      }
    } else {
      setLoginModalShow(true);
    }
  };
  useEffect(() => {
    setShowCart(true);
    setShowAddress(false);
    setShowPayment(false);
    setTabName("cart");
  }, []);
  useEffect(() => {
    console.log(deliveryDetails);
  }, [deliveryDetails]);
  return (
    <Container fluid="xl" className="cartpage p-0">
      {!placeOrderStatus && (
        <div>
          <Row className="">
            <Col md="12">
              <Breadcrumb>
                <Breadcrumb.Item href="/" className="BCHome">
                  <FontAwesomeIcon
                    icon="fa-solid fa-home"
                    size="sm"
                    color="black"
                  />
                </Breadcrumb.Item>
                <Breadcrumb.Item active className="activeBC">
                  {" "}
                  Shopping Cart{" "}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col md="12">
              <h2 className="text-center mb-sm-5 Cheadertxt">Shopping Cart</h2>
            </Col>
          </Row>

          {cartCount > 0 && (
            <div>
              <Row className="CmainBC">
                <Col md="12">
                  <Row className="justify-content-center">
                    <Col className="col-md-2 active-c">
                      <span className="cart-h-icon">
                        <FontAwesomeIcon
                          icon="fa-solid fa-cart-shopping"
                          size="sm"
                          color="#fff"
                        />
                      </span>{" "}
                      Your Cart ({cartCount}){" "}
                      <span className="float-end">
                        <FontAwesomeIcon
                          icon="fa-solid fa-angle-right"
                          size="lg"
                          color="#ccc"
                        />
                      </span>
                    </Col>
                    <Col
                      className={
                        "col-md-2 " +
                        (showAddress || showPayment ? "active-c" : "")
                      }
                    >
                      <span className="cart-h-icon">
                        <FontAwesomeIcon
                          icon="fa-solid fa-truck-fast"
                          size="sm"
                          color="#fff"
                        />
                      </span>{" "}
                      Shipping Details{" "}
                      <span className="float-end">
                        <FontAwesomeIcon
                          icon="fa-solid fa-angle-right"
                          size="lg"
                          color="#ccc"
                        />
                      </span>
                    </Col>
                    <Col
                      className={"col-md-2 " + (showPayment ? "active-c" : "")}
                    >
                      <span className="cart-h-icon">
                        <FontAwesomeIcon
                          icon="fa-solid fa-wallet"
                          size="sm"
                          color="#fff"
                        />
                      </span>{" "}
                      Payment Details{" "}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className=" mt-4 mt-sm-5">
                <Col md="8">
                  {showCart && (
                    <CartDetails
                      cartAmounts={setCartAmount}
                      cartCounts={setCartCount}
                      totalDiscounts={setTotalDiscount}
                    />
                  )}
                  {showAddress && (
                    <ShippingAddress
                      deliveryDetails={deliveryDetails}
                      setdeliveryDetails={setDeliveryDetails}
                    />
                  )}
                  {showPayment && (
                    <PaymentDetails
                      deliveryDetails={deliveryDetails}
                      setdeliveryDetails={setDeliveryDetails}
                    />
                  )}
                </Col>

                <Col md="1"></Col>
                <Col md="3">
                  <Card className="summary">
                    <Card.Body className="px-0">
                      <Card.Title className="text-center">
                        Order Summary
                      </Card.Title>
                      <ListGroup variant="" className="text-start Sumrybody">
                        <ListGroup.Item>
                          Sub Total{" "}
                          <span className="float-end">{cartAmount}</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Total Discount{" "}
                          <span className="float-end">
                            {totalDiscount.toFixed(2)}
                          </span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Delivery Charges{" "}
                          <span className="float-end">Free</span>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                    <Card.Footer>
                      Total Amount{" "}
                      <span className="float-end">
                        {(cartAmount - totalDiscount).toFixed(2)}
                      </span>
                    </Card.Footer>
                  </Card>

                  <Button
                    className="cart-checkout-button w-100 mt-3"
                    disabled={cartAmount == 0 ? "disabled" : ""}
                    onClick={() => handleTabChange(tabName)}
                  >
                    {tabName == "payment"
                      ? "Place Order"
                      : tabName === "shipping"
                      ? "Continue To Payment"
                      : "Continue To Checkout"}
                  </Button>
                </Col>
              </Row>
            </div>
          )}
          {cartCount == 0 && (
            <Row>
              {" "}
              <Alert key="warning" variant="warning" className="text-center">
                Your cart is empty
              </Alert>
            </Row>
          )}
          <Login
            show={loginModalShow}
            tab={"login-mobile"}
            otpScreen={otpScreen}
            mobileNo={mobileNo}
            onHide={() => {
              setLoginModalShow(false);
              setOtpScreen(false);
            }}
            onRegister={() => {
              setRegisterModalShow(true);
              setLoginModalShow(false);
              setOtpScreen(false);
            }}
          />
          <Register
            show={registerModalShow}
            onHide={() => setRegisterModalShow(false)}
            mobileNo={setMobileNo}
            otpShow={() => {
              setRegisterModalShow(false);
              setLoginModalShow(true);
              setOtpScreen(true);
            }}
          />

          <ToastContainer
            className="p-3"
            position="bottom-center"
            style={{ zIndex: 5 }}
          >
            <Toast
              className="d-inline-block m-1"
              bg="danger"
              key={1}
              onClose={() => setToast(false)}
              show={toast}
              delay={5000}
              autohide
            >
              <Toast.Body className="text-white">{toastError}</Toast.Body>
            </Toast>
          </ToastContainer>
        </div>
      )}
      {placeOrderStatus && (
        <Row className="mt-5">
          {" "}
          <Card
            className="col-6 offset-md-3  p-4 text-center"
            style={{ background: "#f8f9fa" }}
          >
            <FontAwesomeIcon
              icon="fa-regular fa-circle-check"
              className="order-success-icon text-danger"
            />

            <h2 className="mt-4 text-danger">Order Successfully Placed</h2>
            <h5 className="my-3">Thank you for your order</h5>
            <p>
              Your Order number is <b>{orderId}</b>. Kindly note this order number for
              your future communication and tracking your order.
            </p>
            <Row className="mt-4"> 
              <Button className="col-3 m-auto bg-dark border-dark"><NavLink to={`/profile/order/${orderId}`}>View Order</NavLink></Button>
              <Button className="col-4 m-auto"><NavLink to="/">Continue Shopping</NavLink></Button>
            </Row>
          </Card>          
        </Row>
      )}
    </Container>
  );
}

export default Cart;
