import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const categoryState = {
    //    updateState: false,
    //    loading: false,
    categoryList: [],
    error: "",
    response: "",
};

export const fetchCategory = createAsyncThunk(
    "category/fetchCategory",
    async (data) => {
        const response = await axios.get(`http://localhost:3003/categories/get/`);
        return response.data;
    }
);

const categorySlice = createSlice({
    name: "category",
    initialState: categoryState,
    reducers: {
        changeStateTrue: (state) => {
            state.updateState = true;
        },
        changeStateFalse: (state) => {
            state.updateState = false;
        },
        clearResponse: (state) => {
            state.response = "";
        },
    },
    extraReducers: (builder) => {
        //        builder
        //                .addCase(addEmployee.pending, (state) => {
        //                    state.loading = true;
        //                })
        //                .addCase(addEmployee.fulfilled, (state, action) => {
        //                    state.loading = false;
        //                    state.employeeList.push(action.payload);
        //                    state.response = "add";
        //                })
        //                .addCase(addEmployee.rejected, (state, action) => {
        //                    state.loading = false;
        //                    state.error = action.error.message;
        //                });

        builder
            .addCase(fetchCategory.fulfilled, (state, action) => {
                //                    console.log(action);
                state.categoryList = action.payload.data;
            })
            .addCase(fetchCategory.rejected, (state, action) => {
                state.error = action.error.message;
            });

        //        builder.addCase(removeEmployee.fulfilled, (state, action) => {
        //            state.employeeList = state.employeeList.filter(
        //                    (item) => item._id != action.payload
        //            );
        //            state.response = "delete";
        //        });
        //
        //        builder.addCase(modifiedEmployee.fulfilled, (state, action) => {
        //            const updateItem = action.payload;
        //            console.log(updateItem);
        //            const index = state.employeeList.findIndex(
        //                    (item) => item._id === updateItem._id
        //            );
        //            if (index !== -1) {
        //                state.employeeList[index] = updateItem;
        //            }
        //            state.response = "update";
        //        });
    },
});

export default categorySlice.reducer;
export const { changeStateTrue, changeStateFalse, clearResponse } =
    categorySlice.actions;


