import { Row, Col, InputGroup, Form, Image } from "react-bootstrap";
import PlayStoreImage from "../../static/images/footer/play-store.png";
import AppStoreImage from "../../static/images/footer/mac.png";
import QrImage from "../../static/images/qr.png";
import mobile from "../../static/images/mobile.png";
import { Link } from "react-router-dom";

function AppContent() {
    const playstoreURL = process.env.REACT_APP_PLAY_STORE_LINK;
    const appstoreURL = process.env.REACT_APP_APP_STORE_LINK;
    // console.log(process.env)
    return (
            <Row className="m-0 ">
                <Row className="appContent">
                    <Col className="mt-0 mt-sm-5 d-none d-sm-block">
                    <img src=
                         {mobile}
                         alt="mobile" />
                    </Col>
                    <Col className="d-flex flex-column align-self-center getAppDiv">
                    <p className="fs-5 w-100 text-center text-sm-center">Get link on mobile</p>
                    <InputGroup className="mb-3 w-75 ms-auto d-none">
                        <Form.Control
                            placeholder="Enter Mobile number"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            id="phone-number"
                            className="p-3 "
                            />
                        <InputGroup.Text
                            id="basic-addon2"
                            className="w-25 p-3 text-center justify-content-center position-absolute end-0"
                            >
                            Get App !
                        </InputGroup.Text>
                    </InputGroup>
                    <Image src=
                           {QrImage}
                           alt="Load in mobile" className="qr-image"  />
                    <p className="fs-2 mt-4 w-100 text-center text-sm-center fw-bold">Download our Mobile APP</p>
                    <p className="fs-5 w-100 text-center text-sm-center">
                        <Link  to=
                               {playstoreURL}
                               title="Download App from playstore" target="_blank">
                        <Image src=
                               {PlayStoreImage}
                               alt="Download Android APP" />
                        </Link>
                        <Link to=
                              {appstoreURL}
                              title="Download App from appstore" target="_blank">
                        <Image src=
                               {AppStoreImage}
                               alt="Download APP from AppStore" />
                        </Link>
                    </p>
            
            
                    </Col>
                </Row>
                <Row className="mt-5">
                    <p>
                        Did you ever think that groceries like the finest pulses and grains,
                        and hundreds of branded items could all be hand-picked and delivered
                        to your house at the touch of a button? Over a thousand of our
                        customers are delighted with Grace online supermarket
                    </p>
                    <p>
                        To Buy grocery Shopping online, Grace has everything you need for your
                        daily needs, from cleaning supplies to beauty and makeup. We have
                        become accustomed to convenience.We've taken away all of the hassles
                        that come with shopping for everyday necessities. You can now order
                        all of your household items and even food online without having to
                        travel long distances or stand in long lines. Add in the convenience
                        of getting all of your necessities in one place, as well as
                        significant cost savings. Grace Supermarket, one of the most popular
                        online supermarkets in India, has changed the way people buy food. Do
                        you require something new? We've got you covered,whether it's fruits
                        and vegetables or dairy, and more online at your convenience.
                        Hassle-free Home Delivery options. We deliver in Chennai and ensure a
                        low delivery time, guaranteeing that all of your items, from groceries
                        to snacks and branded meals, are available.
                    </p>
                    <p>
                        <span className="text-danger fw-bold">
                            Best online grocery store in Chennai
                        </span>
                        {" "}
            
                        Grace supermarket is known for its high quality. It continues to aim
                        for better levels of consumer trust and confidence by listening to
                        customers and delivering them what they want. To our Fresh line, we've
                        added the convenience of pre-cut fruits. If you're looking for a
                        specific product category, we've made it simple to access all of the
                        products in that section. For example, if you want a drink, you can
                        choose from a variety of options, including chilly drinks, hot teas,
                        fruit juices, and more. We are happy to have a close relationship with
                        the farmers who provide us with our fresh produce. The majority of our
                        farm-fresh items come directly from small vendors, ensuring not only
                        the most competitive prices and freshest products for our customers
                        but also better prices for the farmers. Grace Supermarket boasts the
                        greatest choice of organic products in the category, with more than
                        Organic Fruits & Vegetables and a comprehensive range of organic
                        essentials. When it comes to payment, we've made it simple for our
                        customers to use a variety of methods, including credit and debit
                        cards, Internet banking, and cash on delivery (COD). To Buy Best
                        online grocery store in Chennai with Thousands of delighted consumers,
                        have made https://gracesupermarket.s3.ap-southeast-2.amazonaws.com/ their online supermarket of
                        choice due to the convenience of buying for home and daily needs
                        without sacrificing quality.
                    </p>
                </Row>
            </Row>
            );
}
export default AppContent;
