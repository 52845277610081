import { useEffect, useState, createContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
selectCurrentUser,
        selectCurrentToken,
        logOut,
} from "../features/auth/authSlice";
import { json, Link, NavLink } from "react-router-dom";
import logo from "../static/images/e-logo.png";
import Login from "../common/login";
import Register from "../common/register";
import GoogleMapModal from "./liveGmap";
import { useNavigate } from "react-router-dom";
import {
fetchCategory,
        changeStateTrue,
        changeStateFalse,
} from "../features/categorySlice";
import { fetchCart, clearState, addCart } from "../features/cartSlice";
import { fetchSearchProduct, changeLoading, clearSearchProduct } from "../features/productSlice";
import ParentCategory from "../components/menus/parent-category";
import banner from "../static/images/banner.png";
import product from "../static/images/product.png";
import productBanner from "../static/images/p_banner.png";
import product2Banner from "../static/images/product-2.png";
import category from "../static/images/category.png";
import brand from "../static/images/brand.png";
import downBanner from "../static/images/down_banner.png";
import fruits from "../static/images/fruits.png";
import oils from "../static/images/oils.png";
import mobile from "../static/images/mobile.png";
import { useGetAllCategoriesQuery } from "../features/categoryApi";
import {
Container,
        Row,
        Col,
        Image,
        Button,
        Model,
        Stack,
        Form,
        Badge,
        Nav,
        Navbar,
        NavDropdown,
        Carousel,
        Card,
        InputGroup,
        ListGroup,
        Dropdown,
        DropdownButton,
        Tooltip,
        OverlayTrigger,
        FormGroup,
        Offcanvas,
        Accordion
} from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../static/css/App.css";
import {
UserIcon,
        CartIcon,
        WhatsappIconSolid,
} from "../components/icons/icons";
library.add(fas, fab, far);
function Header() {
    const [scroll, setScroll] = useState(false);
    const [loginModalShow, setLoginModalShow] = useState(false);
    const [registerModalShow, setRegisterModalShow] = useState(false);
    const [mobileNo, setMobileNo] = useState("");
    const [otpScreen, setOtpScreen] = useState(false);
    const [gMapModalShow, setgMapModalShow] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState({});
    const [availBranches, setAvailBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [showSearch, setShowSearch] = useState(false);
    const [offCanvasShow, setOffCanvasShow] = useState(false);
    const newRef = useRef(null);
    const lastSelectedLocation = JSON.parse(
            localStorage.getItem("selectedLocation")
            );
    const storedBranches = JSON.parse(localStorage.getItem("availableBranches"));
    const lastselectedBranch = JSON.parse(localStorage.getItem("selectedBranch"));
    // console.log('lastselectedBranch',lastselectedBranch.id);
    const navigate = useNavigate();
    //    const [user, setUser] = useState(useSelector(selectCurrentUser));
    const user = useSelector(selectCurrentUser);
    const token = useSelector(selectCurrentToken);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 10);
        });
        if (lastSelectedLocation) {
            setSelectedLocation({
                area: lastSelectedLocation.area,
                pincode: lastSelectedLocation.pincode,
            });
        } else {
            navigate("/");
            setgMapModalShow(true);
        }
        if (storedBranches) {
            setSelectedBranch(lastselectedBranch);
            setAvailBranches(storedBranches);
        }
        if (lastSelectedLocation && !storedBranches && !lastselectedBranch) {
            navigate("/location-unserviceable");
        }
    }, []);
    const dispatch = useDispatch();
    const [warningTime, setWarningTime] = useState(1000);
    let warnTimeout;
    const warn = () => {
        if (cartStatus) {
            dispatch(addCart());
        }
    };
    const {data: categoryList} = useGetAllCategoriesQuery();
    const {cartCount, cartAmount, cartStatus, wishListCount} = useSelector(
            (state) => state.cart
    );
    const {searchProductList} = useSelector((state) => state.product);
    const setTimeouts = () => {
        warnTimeout = setTimeout(warn, warningTime);
    };
    const clearTimeouts = () => {
        if (warnTimeout)
            clearTimeout(warnTimeout);
    };
    useEffect(() => {
        const events = ["click"];
        const resetTimeout = () => {
            clearTimeouts();
            setTimeouts();
        };
        for (let i in events) {
            window.addEventListener(events[i], resetTimeout);
        }

        setTimeouts();
        return () => {
            for (let i in events) {
                window.removeEventListener(events[i], resetTimeout);
                clearTimeouts();
            }
        };
    }, [cartStatus]);
    //    useEffect(() => {
    //        if (cartStatus) {
    //dispatch(addCart());
    //        }
    //    }, [cartStatus]);
    useEffect(() => {
        // dispatch(fetchCategory());
        dispatch(changeLoading());
        dispatch(fetchCart());
    }, [dispatch]);
    const logouts = async (e) => {
        dispatch(logOut());
        dispatch(clearState());
    };
    useEffect(() => {
        if (availBranches.length > 0) {
            setSelectedBranch(lastselectedBranch);
        } else {
            setSelectedBranch([]);
        }
    }, [availBranches]);
    useEffect(() => {
        if (searchProductList.length > 0) {
            setShowSearch(true);
        } else {
            if (showSearch) {
                dispatch(clearSearchProduct());
                setShowSearch(false);
            }
        }
    }, [searchProductList]);
    const handleBranchSelect = (eventKey) => {
        const selected = availBranches.find(
                (branch) => branch.id === parseInt(eventKey)
        );
        setSelectedBranch(selected);
        localStorage.setItem("selectedBranch", JSON.stringify(selected));
    };
    const handleProductSearch = (e) => {
        var searchValue = e.target.value;
        if (searchValue.length > 1) {
            dispatch(fetchSearchProduct(searchValue));
        } else {
            dispatch(clearSearchProduct());
            setShowSearch(false);
        }
    };
    const truncateName = (name, maxLength) => {
        if (!name) {
            return "";
        }
        if (name.length > maxLength) {
            return `${name.substring(0, maxLength)}...`;
        }
        return name;
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    });
    const handleOutsideClick = (e) => {
        if (newRef.current && !newRef.current.contains(e.target)) {
            if (showSearch) {
                dispatch(clearSearchProduct());
                setShowSearch(false);
            }
        }
    };
    return (
            <Container fluid="xl" className="mb-3">
                <header className="d-none d-sm-block">
                    <Row className="App-header px-0">
                        <Col className="px-md-5 px-xl-2">
                        <Row className="top-header p-2 text-white">
                            <Col className="">
                            <div className="scroller">
                                <span>
                                    Welcome to{" "}
                                    <Image src="https://gracesupermarket.s3.ap-southeast-2.amazonaws.com/front/text-logo.png" alt="Logo" />
                                    <br />
                                    An Iconic Destiny for Grocery Shopping in Wholesale Price
                                    <br />
                                </span>
                            </div>
                            </Col>
                            <Col xs md="4" className="text-end">
                            <span className="rhInfo">
                                <FontAwesomeIcon icon="fa-solid fa-phone" />
                                &nbsp;&nbsp;6033360333 &nbsp;&nbsp;&nbsp;
                                <WhatsappIconSolid />
                                &nbsp;&nbsp;WhatsApp us!
                            </span>
                            </Col>
                        </Row>
                        </Col>
                    </Row>
                    <Row className={`headerMain ${scroll ? "sticky" : ""}`} style={{zIndex: "11"}}>
                        <Container>
                            <div className="d-flex px-md-5 px-xl-0">
                                <Col md="1" className="logo">
                                <Nav.Link href="/">
                                    <Image src={logo} />
                                </Nav.Link>
                                </Col>
                                <Col md="2" className="mt-3">
                                <Stack
                                    direction="horizontal"
                                    gap={3}
                                    onClick={() => setgMapModalShow(true)}
                                    >
                                    <FontAwesomeIcon
                                        icon="fa-solid fa-location-dot"
                                        size="2xl"
                                        style={{cursor: "pointer"}}
                                        />
            
                                    <Col xs="11" className="mt-1">
                                    <span className="w-100 float-start delito">Deliver to</span>
                                    {selectedLocation && selectedLocation.area ? (
                                                    <Button
                                                        variant="link"
                                                        className="p-0 text-decoration-none location-btn btn-bg-none loctxt"
                                                        >
                                                        {truncateName(selectedLocation.area, 8)}/
                                                        {selectedLocation.pincode}
                                                        <FontAwesomeIcon
                                                            className="ms-1"
                                                            icon="fa-solid fa-angle-down"
                                                            size="1x"
                                                            style={{cursor: "pointer"}}
                                                            />
                                                    </Button>

                                                ) : (
                                                    <span style={{color: "#ef4438", cursor: "pointer"}}>
                                                        Location Not Selected
                                                    </span>
                        )}
                                    </Col>
                                </Stack>
                                <GoogleMapModal
                                    showModal={gMapModalShow}
                                    handleCloseModal={() => {
                    setgMapModalShow(false);
                                        }}
                                    onLocationSelect={(location) => setSelectedLocation(location)}
                                    availBranches={(branches) => setAvailBranches(branches)}
                                    />
                                </Col>
                                <Col md="6" className="d-flex align-items-center"  ref={newRef}>
                                <Col xs="12" className="position-relative">
                                <InputGroup>
                                    <div className="search-icon">
                                        <FontAwesomeIcon
                                            className="form-control-feedback"
                                            icon="fa-solid fa-search"
                                            size="1x"
                                            />
                                    </div>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search for products..."
                                        className=" mr-sm-2 bg-light searchBox"
                                        onChange={handleProductSearch}
                                        />
                                </InputGroup>
                                {showSearch && (
                            <Dropdown.Menu show className="w-100 position-absolute">
                                {searchProductList &&
                                        searchProductList.map((pl, index) => (
                                                                                    <Dropdown.Item
                                                                                        eventKey={index}
                                                                                        className="td-none"
                                                                                        href={`/pd/${pl.slug}`}
                                                                                        >
                                                                                        {pl.productName}-{pl.unit}
                                                                                        {pl.unitName}
                                                                                    </Dropdown.Item>
                                                    ))}
                            </Dropdown.Menu>
                        )}
                                {!showSearch && (
                            <Dropdown.Menu hide className="w-100 position-absolute">
                                {" "}
                            </Dropdown.Menu>
                        )}
                                </Col>
                                </Col>
                                <Col md="3" className="d-flex align-items-center">
                                <Col className="col-xl-8">
                                <Stack className="ps-3 justify-content-center" direction="horizontal">
                                    <UserIcon />
                                    {!user || !token ? (
                            <Button
                                variant="link"
                                className="p-0 ULText text-decoration-none btn-bg-none"
                                onClick={() => setLoginModalShow(true)}
                                >
                                Login / register
                            </Button>
                        ) : (
                                                    <DropdownButton               
                                                        className="userDropDown"
                                                        id="dropdown-item-button"
                                                        title={user.name ? user.name : "View Account"}
                                                        >
                                                        <NavLink className="dropdown-item h-b" to="/profile">
                                                            <FontAwesomeIcon icon="fa-solid fa-user" size="1x" />{" "}
                                                            &nbsp; Profile
                                                        </NavLink>
                                                        <NavLink className="dropdown-item h-b" to="/profile/myorders">
                                                            <FontAwesomeIcon icon="fa-solid fa-bag-shopping" size="1x"/>{" "}
                                                            &nbsp; My Orders
                                                        </NavLink>
                                                        <NavLink className="dropdown-item h-b" to="/profile/address">
                                                            <FontAwesomeIcon icon="fa-regular fa-address-card"  size="1x"/>{" "}
                                                            &nbsp; Shipping Address
                                                        </NavLink>
                                                        <NavLink className="dropdown-item h-b" to="/profile/password">
                                                            <FontAwesomeIcon icon="fa-solid fa-lock"  size="1x"/>{" "}
                                                            &nbsp; Change Password
                                                        </NavLink>
                                                        <NavLink className="dropdown-item h-b" to="/profile/notification">
                                                            <FontAwesomeIcon icon="fa-regular fa-bell"  size="1x"/>{" "}
                                                            &nbsp; Notification
                                                        </NavLink>
                                                        <Dropdown.Item as="button" onClick={logouts}>
                                                            <FontAwesomeIcon icon="fa-solid fa-arrow-right-from-bracket"  size="1x"/>{" "}
                                                            &nbsp; Logout
                                                        </Dropdown.Item>
                                                    </DropdownButton>
                        )}
                                </Stack>
                                </Col>
                                <Col className="col-xl-4 d-flex align-items-center justify-content-end">
                                <Nav.Link href="/cart">
                                    <Stack direction="horizontal" gap={1}>
                                        <div className="ciDiv">
                                             <FontAwesomeIcon icon="fa-solid fa-cart-shopping" size="xl"/>
                                        </div>
                                        <div>
                                            <span className="w-100 float-start color-primary hpriceTxt">
                                                ₹{cartAmount}
                                            </span>
                                            <span className="hitemTxt w-100 ">
                                                {cartCount}&nbsp;items
                                            </span>
                                        </div>
                                    </Stack>
                                </Nav.Link>
                                </Col>
                                </Col>
                            </div>
                        </Container>
                    </Row>
                    <Row className={`chRow ${scroll ? "sticky" : ""}`}>
                        <Navbar collapseOnSelect expand="lg" className="p-0">
                            <Col className="px-5 px-xl-2">
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav
                                    fill
                                    className="flex-grow-1 justify-content-evenly fw-bold category-menu"
                                    >
                                    {categoryList &&
                        categoryList.data.map((category, index) => (
                                                                <ParentCategory items={category} key={index} />
                                    ))}
            
                                    <div className="Hnavlink p-2">
                                        {!user || !token ? (
                            <Nav.Link
                                className="Hmonthlisttxt text-theme-green"
                                onClick={() => setLoginModalShow(true)}
                                >
                                <FontAwesomeIcon icon="fa-solid fa-heart " />
                                &nbsp;&nbsp;
                                <span>Monthly List </span>
                            </Nav.Link>
                        ) : (
                                                        <NavLink
                                                            to="/profile/monthly-list"
                                                            className="Hmonthlisttxt text-theme-green text-decoration-none"
                                                            >
                                                            <FontAwesomeIcon icon="fa-solid fa-heart " />
                                                            &nbsp;&nbsp;
                                                            <span>Monthly List ({wishListCount}) </span>
                                                        </NavLink>
                        )}
                                    </div>
                                    <div className="Hnavlink p-2">
                                        <Nav.Link
                                            href="/offers"
                                            className="Hmonthlisttxt text-theme-red"
                                            >
                                            <FontAwesomeIcon icon="fa-solid fa-bullhorn bg-danger" />
                                            &nbsp;&nbsp;
                                            <span>Offers </span>
                                        </Nav.Link>
                                    </div>
                                </Nav>
                            </Navbar.Collapse>
                            </Col>
                        </Navbar>
                    </Row>
                </header>
                <header className="d-block d-sm-none">
                    <Row className="App-header px-0">
                        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary p-0">
                            <Container> 
                                <Col xs="3" className="">
                                <Navbar.Toggle className="border-0" aria-controls="responsive-navbar-nav" onClick={() => setOffCanvasShow(!offCanvasShow)} />
            
                                <Navbar.Offcanvas
                                    placement="end"
                                    show={offCanvasShow}
                                    id={`offcanvasNavbar-expand-nav`}
                                    aria-labelledby={`offcanvasNavbarLabel-expand-nav`}
            
                                    >
                                    <Offcanvas.Header>
                                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-nav`}>
                                            Categories
                                        </Offcanvas.Title>
                                        <span className="ms-auto"  onClick={() => setOffCanvasShow(!offCanvasShow)}><FontAwesomeIcon icon="fa-solid fa-xmark" size="xl" /></span>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav className="justify-content-end flex-grow-1 pe-3">
                                            <Accordion defaultActiveKey="0">
                                                {categoryList &&
                        categoryList.data.map((category, index) => (
                                                                            <ParentCategory items={category} keys={index} key={index} setOffCanvasShow={setOffCanvasShow} />
                                    ))}
                                            </Accordion>                                
                                        </Nav>                            
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                                </Col>
                                <Col xs="4" className="logo text-start">
                                <Nav.Link href="/">
                                    <Image src={logo} />
                                </Nav.Link>
                                </Col>   
                                <Col xs="3" className="profile">
                                <Stack className="text-start gap-1" direction="horizontal">
                                    <UserIcon />
                                    {!user || !token ? (
                            <Button
                                variant="link"
                                className="p-0 ULText text-decoration-none btn-bg-none text-start"
                                onClick={() => setLoginModalShow(true)}
                                >
                                Login
                            </Button>
                        ) : (
                                                    <NavLink className="dropdown-item h-b" to="/profile">
                                                        {user.name ? user.name : "View Account"}
                                                    </NavLink>)
                                    }
                                </Stack>
                                </Col> 
                                <Col xs="2" className="cart">
                                <Nav.Link href="/cart">
                                    <Stack direction="horizontal" gap={1}>
                                        <div className="ciDiv">
                                            <FontAwesomeIcon icon="fa-solid fa-cart-shopping" size="lg"/>
                                        </div>
                                        <span className="hitemTxt w-100 ">
                                            {cartCount}
                                        </span>
            
                                    </Stack>
                                </Nav.Link>
                                </Col> 
                            </Container>
                        </Navbar>
                    </Row>
                    <Row>
                        <Col className="d-flex align-items-center p-0">
                        <Col xs="12" className="position-relative">
                        <InputGroup>
                            <div className="search-icon">
                                <FontAwesomeIcon
                                    className="form-control-feedback"
                                    icon="fa-solid fa-search"
                                    size="1x"
                                    />
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Search for products..."
                                className=" mr-sm-2 bg-light searchBox"
                                onChange={handleProductSearch}
                                />
                        </InputGroup>
                        {showSearch && (
                            <Dropdown.Menu show className="w-100 position-absolute">
                                {searchProductList &&
                                        searchProductList.map((pl, index) => (
                                                                            <Dropdown.Item
                                                                                eventKey={index}
                                                                                className="td-none"
                                                                                href={`/pd/${pl.slug}`}
                                                                                >
                                                                                {pl.productName}-{pl.unit}
                                                                                {pl.unitName}
                                                                            </Dropdown.Item>
                                                    ))}
                            </Dropdown.Menu>
                        )}
                        {!showSearch && (
                            <Dropdown.Menu hide className="w-100 position-absolute">
                                {" "}
                            </Dropdown.Menu>
                        )}
                        </Col>
                        </Col>
                    </Row>
                </header>
            
                <Login
                    show={loginModalShow}
                    tab={"login-mobile"}
                    otpScreen={otpScreen}
                    mobileNo={mobileNo}
                    onHide={() => {
                    setLoginModalShow(false);
                            setOtpScreen(false);
                        }}
                    onRegister={() => {
                    setRegisterModalShow(true);
                            setLoginModalShow(false);
                            setOtpScreen(false);
                        }}
                    />
                <Register
                    show={registerModalShow}
                    onHide={() => setRegisterModalShow(false)}
                    mobileNo={setMobileNo}
                    otpShow={() => {
                    setRegisterModalShow(false);
                            setLoginModalShow(true);
                            setOtpScreen(true);
                        }
                    }
                      onLogin={() => {
                    setLoginModalShow(true);
                            setRegisterModalShow(false);
                            setOtpScreen(false);
                        }}
                    />
            </Container>
            );
}



export default Header;
